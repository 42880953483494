import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from '../components/Footer';

export default function Faqs() {
    const [FAQs] = useState([
        {
            Q: "How does the watertight door work? ",
            A: "The watertight door of the Energy Tubs walk-in tub forms a hydrostatic seal when the door is closed and latched. Additionally, the weight of water inside the tub further compresses the dear seal making it completely leak proof. Energy Tubs guarantees the door seal. "
        },
        {
            Q: "Can the Energy Tubs walk-in tub be used as a shower?",
            A: "Yes, the Energy Tubs deluxe faucet set cornea with an extendable/retractable hand-held showerhead that is typically mounted onto the deck of the tub_ If you choose, your installer can mount the handheld showerhead on the wall."
        },

        {
            Q: "Do Energy Tubs walk-in tubs have an electrical safety certification? ",
            A: "Yes, Energy Tubs is one of the few walk-in tub manufacturers with a UL certification. The UL listing provides you and your customers with a very strong indication of the safety of an Energy Tubs walk-in bath_ UL is an independent product safety certification organization who has been testing and evaluating products for over 116 years. They are world-regarded as the best in the business. More specifically, UL does extensive testing of a product against UL safety standards_ If the product is approved, the product is labeled with a UL Listing Mark. Look for this UL Listing Mark...don't buy a walk-in tub that doesn't have this mark prominently displayed in their website, literature and on the tub. "
        },

        {
            Q: "How are Energy Tubs walk-in tubs installed?",
            A: "Energy Tubs walk-in tub can be installed by any capable, licensed plumber in as little as two days. Because Energy Tubs walk-in bathtubs frt regular tub spaces, no major remodeling is needed. All Energy Tubs walk-in tubs come in both left-hand and right-hand walk-in versions. Included with every tub delivery is a set of extensions that will enable an existing, standard bathtub space to accommodate the walk-in tub_ Additionally, Energy Tubs can put you in touch with a capable installer serving your locality_ "
        },

        {
            Q: "What tests are performed before the tubs are shipped? ",
            A: "All Energy Tubs walk-in tubs are inspected and tested 100% at our California facility prior to shipment to our customers_ Each tub's watertight integrity is thoroughly tested as are any installed massage systems. Faucetry and all other plumbing fixtures are also thoroughly inspected for quality and functionality."
        },

    ])
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
        }
    }, [])

    return (
        <div>
            <Header />
            <div style={{ paddingTop: '20vh', textAlign: 'center' }}>
                <h3>FAQs: Frequently Asked Question</h3>
                <div className='container' style={{ textAlign: 'start' }}>
                    {
                        FAQs.map(faqItem => {
                            return (
                                <div style={{ marginTop: '2vh', marginBottom: '2vh' }}>
                                    <div style={{ fontSize: '2vh', fontWeight: '500' }}>{faqItem.Q}</div>
                                    <div style={{ fontSize: '1.6vh' }}>{faqItem.A}</div>
                                </div>
                            );
                        })
                    }
                    <br />
                    <br />
                    <br />
                    {/* <h2 style={{ textAlign: 'center' }}>Benefits of owning Walk-In Tub</h2>
                    <div className='container'>
                        <div className='row' style={{ textAlign: 'center' }}>
                            <div className='col-md-3'>
                                <Image style={{ width: '100%', padding: '40pt' }} src={'/images/StressRelief.png'} />
                                <h5>Shop online</h5>
                                <p>Shop your walkin tub online</p>
                            </div>
                            <div className='col-md-3'>
                                <Image style={{ width: '100%', padding: '40pt' }} src={'/images/PainRelief.png'} />
                                <h5>Get tub delivered</h5>
                                <p>Get you walkin delivered to your doorstep</p>
                            </div>
                            <div className='col-md-3'>
                                <Image style={{ width: '100%', padding: '40pt' }} src={'/images/Excercise.png'} />
                                <h5>Best Quality tubs</h5>
                                <p>We offer superior quality</p>
                            </div>
                            <div className='col-md-3'>
                                <Image style={{ width: '100%', padding: '40pt' }} src={'/images/Rehabilitation.png'} />
                                <h5>Best Customer Support</h5>
                                <p>Our customer support team is alway there for you</p>
                            </div>
                        </div>
                    </div> */}

                    <br />
                </div>
            </div>
            <Footer />
        </div>

    )
}
