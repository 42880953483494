import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom';
import './CreateAccount.css'
import serverConfig from './serverConfig';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from './Footer';
import React from 'react';
import Header from './Header';

function CreateAccount(props) {
    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [telephone, settelephone] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zip, setzip] = useState("");
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
        }
    }, [])

    return (
        <>
            <Header />
            <div style={{height:'17vh'}}></div>
            <form onSubmit={(event) => {

                event.preventDefault();
                var valid = true;
                if (username === "") {
                    document.getElementById('usernameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (password === "") {
                    document.getElementById('passwordField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (fname === "") {
                    document.getElementById('fnameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (lname === "") {
                    document.getElementById('lnameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (telephone === "") {
                    document.getElementById('telephoneField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (address === "") {
                    document.getElementById('addressField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (city === "") {
                    document.getElementById('addressField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (state === "") {
                    document.getElementById('addressField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (zip === "") {
                    document.getElementById('addressField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                    valid = false;
                }
                if (valid) {

                    axios({
                        method: "post",
                        data:
                        {
                            username: username,
                            password: password,
                            fname: fname,
                            lname: lname,
                            telephone: telephone,
                            address: address,
                            city: city,
                            state: state,
                            zip: zip
                        },
                        url: `${serverConfig.hostUrl}/users/insertuser`
                    }).then(_ => {
                        console.log(_);
                    }).catch(_ => {
                        console.log(_)
                    })

                    props.history.push('/account')
                } else {
                    alert("Form Invalid")
                }

            }}
                className="container" style={{
                    marginTop: "5%",
                    width: "350px",
                }}>
                <h1 style={{
                    fontWeight: "lighter"
                }}>Create Account</h1>
                <hr />

                <div className="row">
                    <div className="col-sm-12">
                        <label for="usernameField" style={{
                            marginLeft: "15px"
                        }}>Email</label>
                        <input id="usernameField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setusername(word.target.value)
                                document.getElementById('usernameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-sm-12">
                        <label for="passwordField" style={{
                            marginLeft: "15px"
                        }}>Password</label>
                        <input type="password" id="passwordField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setpassword(word.target.value)
                                document.getElementById('passwordField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                        <div style={{ textAlign: "end", marginRight: "15px" }}>
                            <i className="btn" id="hideShow"
                                onMouseEnter={
                                    _ => {
                                        document.getElementById('passwordField').setAttribute('type', 'text')
                                        document.getElementById('hideShow').innerText = "Hide Password"
                                    }
                                }
                                onMouseLeave={
                                    _ => {
                                        document.getElementById('passwordField').setAttribute('type', 'password')
                                        document.getElementById('hideShow').innerText = "Show Password"
                                    }
                                }
                            >Show Password</i></div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <label for="fnameField" style={{
                            marginLeft: "15px"
                        }}>First Name</label>
                        <input id="fnameField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setfname(word.target.value)
                                document.getElementById('fnameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <label for="lnameField" style={{
                            marginLeft: "15px"
                        }}>Last Name</label>
                        <input id="lnameField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setlname(word.target.value)
                                document.getElementById('lnameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-sm-12">
                        <label for="telephoneField" style={{
                            marginLeft: "15px"
                        }}>Telephone</label>
                        <input id="telephoneField" type="telephone" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}

                            onChange={(word) => {
                                settelephone(word.target.value)
                                document.getElementById('telephoneField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }} />
                    </div>
                </div>
                <br />

                <div className="row">
                    <div className="col-sm-12">
                        <label for="addressField" style={{
                            marginLeft: "15px"
                        }}>Address</label>
                        <input id="addressField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setaddress(word.target.value)
                                document.getElementById('addressField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <label for="cityField" style={{
                            marginLeft: "15px"
                        }}>City</label>
                        <input id="cityField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setcity(word.target.value)
                                document.getElementById('cityField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <label for="stateField" style={{
                            marginLeft: "15px"
                        }}>State</label>
                        <input id="stateField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setstate(word.target.value)
                                document.getElementById('stateField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-sm-12">
                        <label for="zipField" style={{
                            marginLeft: "15px"
                        }}>Zip</label>
                        <input id="zipField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}

                            onChange={(word) => {
                                setzip(word.target.value)
                                document.getElementById('zipField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br />

                <br />
                <button type="submit" className="btn btn-success col-sm-12 focusedGRN" style={{
                    borderRadius: "20px"
                }}>CREATE ACCOUNT</button>
                <br />
                <br />
                <Link to='/' className="btn btn-outline-dark col-sm-12 focusedBLK" style={{
                    borderRadius: "20px"
                }}>CANCEL</Link>
                <div style={{ textAlign: "right", padding: "10pt" }}>
                    <Link to={'/'} style={{ textDecoration: "none" }}>Go Back</Link>
                </div>
                <br />
                <br />
                <br />
            </form>
            <div style={{ width: '100vw' }}>
                <Footer />
            </div>
        </>
    );
}
export default CreateAccount;