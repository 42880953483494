/* eslint-disable no-unused-vars */
// import styled from 'styled-components';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './Accounts.css'
import Footer from './Footer';
// import axios from 'axios';
import React from 'react';

function LogginInforShop(props) {
    // var imported = false;
    const [username, setusername] = useState("")
    const [password, setpassword] = useState("")
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [telephone, settelephone] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zip, setzip] = useState("");
        // axios.post(`${serverConfig.hostedUrl}/info/getuser`,{username:localStorage.getItem('username')},{method:"POST"}).then(_ => {
        // var user =  _.data   
        // // console.log(user);
        // setaddress(user.address)
        // setcity(user.city)
        // setfname(user.fname)
        // setlname(user.lname)
        // setpassword(user.password)
        // setstate(user.state)
        // settelephone(user.telephone)
        // setusername(user.username)
        // setzip(user.zip)
        // imported = true
        // })
        // sign in
        useEffect(() => {
            window.scrollTo(0, 0)
          return () => {
          }
        }, [])
        
        return (
            <div className="container" style={{
                marginTop: '5%',
                width: "340px",
            }}>
                <div className="row">
                    <div>
                        <h1
                            style={{
                                fontWeight: "lighter",
                                marginLeft: "15px"
                            }}
                        >Sign In</h1>
                        <hr />
                        <form onSubmit={(event) => {
                            var valid = true;
                            console.log(username);
                            console.log(password);
                            event.preventDefault();
                            if (username === "") {
                                document.getElementById('usernameField').setAttribute('style', "border: 1px solid red;border-radius:20px;textAlign:'center'")
                                valid = false;
                            }
                            if (password === "") {
                                document.getElementById('passwordField').setAttribute('style', "border: 1px solid red;border-radius:20px;textAlign:'center'")
                                valid = false;
                            }
                            if (valid) {
                                // alert("login event will be put here")
                                // sign in login
                                localStorage.setItem('username', username)
                                localStorage.setItem('fname', fname)
                                localStorage.setItem('lname', lname)
                                localStorage.setItem('telephone', telephone)
                                localStorage.setItem('address', address)
                                localStorage.setItem('city', city)
                                localStorage.setItem('state', state)
                                localStorage.setItem('zip', zip)

                                return props.history.push('/paynow')
                            } else {
                                alert("Form Invalid")
                            }
                        }}>
                            <label for='usernameField' style={{
                                marginLeft: "15px"
                            }}
                            >Enter Username</label>
                            <input id='usernameField' className="form-control hoverAbleButton" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setusername(word.target.value);
                                    document.getElementById('usernameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;textAlign:'center'")
                                }}
                            />
                            <br />
                            <label for='passwordField' style={{
                                marginLeft: "15px"
                            }}
                            >Password</label>
                            <input id='passwordField' className="form-control hoverAbleButton"
                                type="password"
                                style={{
                                    borderRadius: "20px",
                                    textAlign: "center"
                                }}
                                onChange={(word) => {
                                    setpassword(word.target.value);
                                    document.getElementById('passwordField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;textAlign:'center'")
                                }}
                            />
                            <div style={{ textAlign: "end", marginRight: "15px" }}>
                                <i className='btn' id='hideShow' onMouseEnter={
                                    _ => {
                                        document.getElementById('hideShow').innerText = 'Hide Password'
                                        document.getElementById('passwordField').setAttribute('type', 'text')
                                    }}
                                    onMouseLeave={
                                        _ => {
                                            document.getElementById('hideShow').innerText = 'Show Password'
                                            document.getElementById('passwordField').setAttribute('type', 'password')
                                        }}>Show Password</i>
                            </div>
                            <br />

                            <button type="submit" className="btn btn-primary col-sm-12 hoverAbleButtonGRN" style={{
                                borderRadius: "20px",
                                marginBottom: "10px",
                                borderWidth: "2px"
                            }}
                            >SIGN IN</button>
                            <br />

                            

                            <Link to='/' className="btn btn-light col-sm-12 hoverAbleButtonCNO" style={{
                                borderRadius: "20px",
                                backgroundColor: "lightGray",
                            }}
                            >CANCEL</Link>
                            <br /><br />
                            <div style={{ textAlign: "right", marginRight: "25px" }}>
                                Have you <Link>forgot</Link> your password</div>
                            <br />
                            <div className='row' style={{
                                textAlign: "center",
                                marginBottom: "20px"
                            }}>
                                <div className='col-sm-5'><hr /></div>
                                <div className='col-sm-2'>OR</div>
                                <div className='col-sm-5'><hr /></div>
                            </div>

                            <Link to='/createAccount' className="btn btn-outline-success col-sm-12 hoverAbleButtonCNOGRN" style={{
                                borderRadius: "20px"
                            }}>
                                Create Account
                            </Link>

                        </form>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Footer/>
            </div>

        );
    }

export default LogginInforShop;
