import axios from 'axios'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import serverConfig from './serverConfig'
function Footer({ pid }) {
  const [Email, setEmail] = useState("")
  const [ShowSuccess, setShowSuccess] = useState(false)
  const [AlreadySubed, setAlreadySubed] = useState(false)
  const [MailFailure, setMailFailure] = useState(false)
  const [SpinnerLoadingMail, setSpinnerLoadingMail] = useState(false)

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  return (
    <div style={{ width: '100%', marginTop: '10vh' }}>
      <div style={{
        height: '50vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly',
        backgroundImage: 'url(/images/toiletTilesTowel.jpeg)', backgroundPosition: 'center', backgroundSize: 'cover', backgroundAttachment: 'fixed',
        color: 'white', paddingTop: '10vh'
      }}>
        <h3>Build You Own Tub</h3>
        <p>Start customizing your own personal walk-in tub</p>
        <p>Learn more about sizing, customizable add-ons and warranty options to meet any budget</p>
        <Link to={`/custom?pid=${pid}`} className='btn btn-primary' style={{ backgroundColor: 'rgba(16,82,134,0.9)', paddingLeft: '2%', paddingRight: '2%', borderRadius: '20pt' }}>Build your own</Link>
        <div style={{ height: '10vh' }}></div>
      </div>
      <div style={{ minHeight: '40vh', width: '100%', backgroundColor: '#222222', color: 'white', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <div className='container' style={{ minHeight: '40vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
          <div className='row' style={{ marginTop: '10vh' }}>
            <div className='col-md-3' style={{ marginBottom: '4vh' }}>
              <h5>Products</h5>
              <div>

                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/custom?pid=0'}>Walk-in Bathtubs</Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/focused?title=Therapy Systems&description=&description2=Matching Faucet, glass door and pillow as added touch&backgroundImg=TherapySystems.png&leftBtnText=Order%20now&rightBtnText=Learn%20more'}>Therapy Systems</Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/Accessories'}>Accessories</Link>
              </div>
            </div>
            <div className='col-md-3' style={{ marginBottom: '4vh' }}>
              <h5>Shop by Size</h5>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/focused?title=Standard%20Series&description=%20Designed%20to%20fit%20in%20place%20of%20regular%20home%20tub&description2=%20Great%20Models%20that%20fit%20most%20bathroom%20layouts&backgroundImg=StandardSeriesHome.png&leftBtnText=Order%20now&rightBtnText=Learn%20more'}>Standard</Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/focused?title=Compact%20Series&description=%20Equipped%20for%20small%20spaces&description2=%20This%20tub%20does%20not%20sacrifice%20comfort%20for%20footprint.&backgroundImg=CompactSeriesHome.png&leftBtnText=Order%20now&rightBtnText=Learn%20more'}>Compact</Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/focused?title=Deep%20and%20Wide%20Series&description=%20Well%20equipped%20for%20deep%20soaking&description2=%20The%20perfect%20bathing%20experiences&backgroundImg=DeepAndWideHome.png&leftBtnText=Order%20now&rightBtnText=Learn%20more'}>Deep and Wide</Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/focused?title=ADA%20and%20Wheelchair%20Series&description=%20Easy%20to%20enter&description2=%20Ease%20of%20use%20when%20bathing&backgroundImg=WheelChairHome.png&leftBtnText=Order%20now&rightBtnText=Learn%20more'}>Wheelchair</Link>
              </div>
            </div>
            <div className='col-md-3' style={{ marginBottom: '4vh' }}>
              <h5>Support</h5>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/contact-us'}>
                  Contact us
                </Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/faqs'}>
                  FAQs
                </Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/contact-us'}>
                  Learn
                </Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/policies'}>
                  Shipping and Return
                </Link>
              </div>
              <div>
                <Link onClick={() => window.scroll({
                  top: 0,
                  left: 0,
                })}
                  style={{ textDecoration: 'none', color: 'rgb(200,200,200)' }}
                  to={'/warrenty'}>
                  warranty
                </Link>
              </div>

            </div>
            <div className='col-md-3'>
              <h5>Get more information</h5>
              <input
                value={Email} onChange={(e) => setEmail(e.target.value)} className='form-control' placeholder='email' />
              {
                (!ValidateEmail(Email) && Email !== "") &&
                <div style={{ color: 'red' }}>
                  Invalid Email
                </div>
              }
              {
                ShowSuccess === true &&
                <div style={{ color: 'greenyellow', marginTop: '10pt', marginBottom: '10pt' }}>We've recieved you request for newsletter</div>
              }
              {
                AlreadySubed === true &&
                <div style={{ color: 'greenyellow', marginTop: '10pt', marginBottom: '10pt' }}>You are already subscribed</div>
              }
              {
                MailFailure === true &&
                <div style={{ color: 'red', marginTop: '10pt', marginBottom: '10pt' }}>Something went wrong, try again later</div>
              }

              <button onClick={() => {
                axios.post(`${serverConfig.hostUrl}/newsletter/register`, {
                  email: Email
                })
                  .then(data => {
                    setSpinnerLoadingMail(true)
                    if (data.data.message === "OK") {
                      // Send Email
                      axios.post(`${serverConfig.hostUrl}/mailer/subscribeEmail`, {
                        subscriber: Email
                      })
                        .then(_ => {
                          setShowSuccess(true)
                          setEmail("")
                          setTimeout(() => {
                            setShowSuccess(false)
                            setSpinnerLoadingMail(false)
                          }, 5000);
                        })
                        .catch(_ => {
                          setMailFailure(true)
                          setEmail("")
                          setTimeout(() => {
                            setMailFailure(false)
                            setSpinnerLoadingMail(false)
                          }, 5000);
                        })
                    } else {
                      setAlreadySubed(true)
                      setEmail("")
                      setTimeout(() => {
                        setAlreadySubed(false)
                        setSpinnerLoadingMail(false)
                      }, 5000);
                    }
                  })
                  .catch(error => {
                    console.log(error);
                  })

              }} className='btn btn-primary col-12'
                disabled={(!ValidateEmail(Email) || Email === "")}
                style={{ marginTop: '5pt', backgroundColor: "rgba(16,82,134,0.9)",display:'flex',alignItems:"center",justifyContent:'space-around' }}
              >Submit {
                  SpinnerLoadingMail && false &&
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only"></span>
                  </div>
                }</button>
            </div>

          </div>
          <div style={{ marginTop: '4vh' }}>
            <p style={{ textAlign: 'center' }}>Energytubs © 2022. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
