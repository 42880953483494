import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Faqs from './Faqs'
import Footer from './Footer'
import Header from './Header'

function Contactus() {
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
        }
    }, [])

    return (
        <div>
            <Header />
            <Faqs />
            <div style={{ paddingTop: '1vh' }}>
                <div className='container'>
                    {/* <div className='row'>
                        <div className='col-md-12'>
                            <div style={{
                                backgroundImage: 'linear-gradient(to bottom right,rgb(0,0,0),rgb(100,100,100))', width: '100%', minHeight: '30vh',
                                borderRadius: '10pt', boxShadow: '1px 1px 10px rgb(200,200,200)', color: 'white',
                                backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                                display: 'flex', flexDirection: 'column', padding: '30pt', justifyContent: 'center'
                            }}>
                                <div className='row'>
                                    <div className='col-md-6' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                        <div>
                                            <div style={{
                                                backgroundColor: 'rgb(50,50,50,0.6)', paddingLeft: '10pt', paddingRight: '10pt',
                                                width: 'fit-content', fontSize: 'xx-large', borderRadius: '10pt'
                                            }}>We offer superior Customer Service</div>
                                        </div>
                                        <br />
                                        <div>
                                            <Link to={'/'} className='btn btn-primary' style={{ borderRadius: '20pt', paddingLeft: '20pt', paddingRight: '20pt' }}>Shop Walk-in Tubs</Link>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <Image style={{ width: '100%' }} src={'/images/CustomerSupportClipart.png'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <br />
                    <div className='row'>
                        <div className='col-md-3'>
                            <Image style={{ width: '100%', padding: '40pt' }} src={'/images/ShopOnline.png'} />
                            <h5>Shop online</h5>
                            <p>Shop your walkin tub online</p>
                        </div>
                        <div className='col-md-3'>
                            <Image style={{ width: '100%', padding: '40pt' }} src={'/images/DeliveryBox.png'} />
                            <h5>Get tub delivered</h5>
                            <p>Get you walkin delivered to your doorstep</p>
                        </div>
                        <div className='col-md-3'>
                            <Image style={{ width: '100%', padding: '40pt' }} src={'/images/SuperiorQuality.png'} />
                            <h5>Best Quality tubs</h5>
                            <p>We offer superior quality</p>
                        </div>
                        <div className='col-md-3'>
                            <Image style={{ width: '100%', padding: '40pt' }} src={'/images/CustomerSupportAgent.png'} />
                            <h5>Best Customer Support</h5>
                            <p>Our customer support team is alway there for you</p>
                        </div>
                    </div>
                    <br />
                    <br /> */}
                    <h3>Contact us</h3>
                    <br />
                    <p>Expert Customer service, 7-days a week </p>
                    <br />
                    <div className='row'>
                        <div className='col-md-4'>
                            <h4>Phone</h4>
                            <br />
                            <div>1-800-655-8085</div>
                            <br />
                            <div>US based helpline</div>
                            <div>8am - 8pm CST Monday to Friday</div>
                            <div>9am - 6pm CST Saturday</div>
                        </div>
                        <div className='col-md-4'>
                            <h4>Email</h4>
                            <br />
                            <div>sales@energytubs.com</div>
                            <br />
                            <div>To help us address your inquiry as quickly as possible, please include your email:</div>
                            <ul>
                                <li>Your name</li>
                                <li>Contact number</li>
                                <li>Product model or seriel number</li>
                            </ul>
                        </div>
                        <div className='col-md-4'>
                            <h4>Delivery and Returns</h4>
                            <br />
                            <div>View delivery and returns status</div>
                            <br />
                            <div>
                                <Link to='/policies'>See our returns policy</Link>
                            </div>
                            <div>
                                <Link to='/policies'>See delivery information</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer for this details kind of page */}
            <br />
            <hr />
            <Footer />
        </div>
    )
}

export default Contactus