import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../../node_modules/bootstrap/dist/js/bootstrap.min'


// import headerLogo from './components/img/etslogo2.png'


import React, { useState } from "react";
import styled from "styled-components";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import headerLogo from './img/etslogo2.png'




import "./Header.css";
function Header(props) {

  const [burgerStatus, setBurgerStatus] = useState(false);

  const changeBurgerStatus = () => {
    setBurgerStatus(!burgerStatus);
    if (burgerStatus) {
      document.getElementById('NAVBARTOP').setAttribute('style', "transform: translate(0%,0%) rotate(0deg);background-color:rgba(255,255,255,0.95);transition:1s");
    }
    else {
      document.getElementById('NAVBARTOP').setAttribute('style', "transform: translate(85%,100vh) rotate(270deg);background-color:rgba(255,255,255,0.95);transition:1s");
    }
  };
  function scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
    localStorage.removeItem('title')
    localStorage.removeItem('bgimg')
    localStorage.removeItem('side')
  }
  return (
    <div>

      <div>
        <BurgerNav show={burgerStatus}
          onClick={changeBurgerStatus}
          style={{
            boxShadow: "1px 1px 20px gray",
            backgroundColor: "rgba(255,255,255,0.9)",
            backdropFilter: "blur(5px)",
          }}>
          <CloseWrapper>
            <CustomClose onClick={changeBurgerStatus} />
          </CloseWrapper>


          <Link onClick={scrollTop} to="/" className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Home</div></Link>
          <Link onClick={scrollTop} to='/focused?title=Compact Series&description=
    Equipped for small spaces&description2=
    This tub does not sacrifice comfort for footprint.&backgroundImg=CompactSeriesHome.png&leftBtnText=Order now&rightBtnText=Learn more' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Compact Series</div></Link>
          <Link onClick={scrollTop} to='/focused?title=Standard Series&description=
    Designed to fit in place of regular home tub&description2=
    Great Models that fit most bathroom layouts&backgroundImg=StandardSeriesHome.png&leftBtnText=Order now&rightBtnText=Learn more' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Standard Series</div></Link>
          <Link onClick={scrollTop} to='/focused?title=Wheelchair Series&description=
    Designed to fit in place of regular home tub&description2=
    Great Models that fit most bathroom layouts&leftBtnText=Order now&rightBtnText=Learn more' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Wheelchair Series</div></Link>
          <Link onClick={scrollTop} to='/focused?title=Deep and Wide Series&description=
    Easy to enter&description2=
    Ease of use when bathing&backgroundImg=DeepAndWideHome.png&leftBtnText=Order now&rightBtnText=Learn more' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Deep and Wide Series</div></Link>
          <Link onClick={scrollTop} to='/Accessories' className='BTNTAB2'>
            <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>Accessories</div></Link>
          <Link onClick={scrollTop} to='/faqs' className='BTNTAB2'>
            <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>FAQs</div></Link>
          <Link onClick={scrollTop} to='/warrenty' className='BTNTAB2'>
            <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>Warrenty</div></Link>
          <Link onClick={scrollTop} to='/contact-us' className='BTNTAB2'>
            <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>Contact us</div></Link>
          {/* <Link onClick={scrollTop} to='/Gettoknow' className='BTNTAB2'>
          <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>Get to know</div></Link> */}
          <Link to='/focused?title=Therapy Systems&description2=Matching Faucet, glass door and pillow as added touch&backgroundImg=TherapySystems.png&leftBtnText=Order now&rightBtnText=Learn more' className='BTNTAB2'>
            <div onClick={scrollTop} className="BTNTAB2AID" style={{ fontSize: "larger" }}>Therapy System</div></Link>
          <br />
          <hr />
          <br />
          <Link onClick={scrollTop} to='/custom?pid=0' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Build your own</div></Link>
          <br />
          <hr />
          <br />
          <Link onClick={scrollTop} to='/Account' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Account</div></Link>
          <Link onClick={scrollTop} to='/custom?pid=0' className='BTNTAB2'>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Shop</div></Link>
          {localStorage.getItem('username') && <div className='BTNTAB2SIGNOUT btn btn-outline-danger' onClick={() => {
            if (localStorage.getItem('username')) {
              localStorage.removeItem('username');
              setBurgerStatus(!burgerStatus);
              window.location.reload(true);
            }
          }}>
            <div className="BTNTAB2AID" style={{ fontSize: "larger" }}>Sign Out</div></div>}

        </BurgerNav>
        <div id="NAVBARTOP" className='fixed-top'>
          <nav style={{ backgroundColor: 'black', color: 'white', textTransform: 'uppercase' }}>
            <div className='container' style={{ fontSize: '1.5vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '5pt', paddingBottom: '5pt', flexWrap: 'wrap' }}>
              <div>
                For parts or warranty assistance, please call 1-800-655-8085
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', }}>
                {/* <div>
                  <Link to={'/account'} style={{ textDecoration: 'none', color: 'white' }}>Account</Link>
                </div>
                <div>
                  &nbsp;&nbsp; | &nbsp;&nbsp;
                </div> */}
                <div>
                  <Link to={'/warrenty'} style={{ textDecoration: 'none', color: 'white' }}>warranty</Link>
                </div>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-xl navbar-light shadow-lg" style={{ backgroundColor: "rgba(255,255,255,0.95)" }} >
            <Link onClick={scrollTop} className="navbar-brand" to='/' style={{ fontSize: "x-large", textDecoration: "none", color: "black", marginLeft: "1%" }}>
              <table>
                <tbody>
                  <tr><td><img alt="" style={{ width: "130pt" }} src={headerLogo} /></td>
                  </tr>
                </tbody>
              </table>
            </Link>

            {/* Menu Right Account Shop */}
            <ul className="navbar" style={{ position: "absolute", right: "0.1%", listStyle: "none" }}>

              <div className='collapse navbar-collapse'>
                <li onClick={scrollTop} style={{ borderRadius: "8px", marginTop: "6%" }} className="nav-item BTNTAB">
                  {/* <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/account'>
                    Account</Link> */}
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/contact-us'>
                    Support</Link>
                </li>
              </div>
              <li style={{ borderRadius: "8px", marginTop: "6%" }} className="nav-item BTNTAB" onClick={changeBurgerStatus}>
                <div style={{ textDecoration: "none", color: "black", cursor: "pointer" }} className='nav-link' ><CustomMenu />
                </div>
              </li>
            </ul>

            {/* Center Links */}
            <div className="collapse navbar-collapse" id="navbarNav" style={{
              justifyContent: "center"
            }}>
              <ul className="navbar-nav">
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/'>
                    Home</Link>
                </li>
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/focused?title=Compact Series&description=
    Equipped for small spaces&description2=
    This tub does not sacrifice comfort for footprint.&backgroundImg=CompactSeriesHome.png&leftBtnText=Order now&rightBtnText=Learn more'>
                    Compact</Link>
                </li>
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/focused?title=Standard Series&description=
    Designed to fit in place of regular home tub&description2=
    Great Models that fit most bathroom layouts&backgroundImg=StandardSeriesHome.png&leftBtnText=Order now&rightBtnText=Learn more'>
                    Standard</Link>
                </li>
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/focused?title=Deep and Wide Series&description=
    Well equipped for deep soaking&description2=
    The perfect bathing experiences&backgroundImg=TherapySystems.png&leftBtnText=Order now&rightBtnText=Learn more'>
                    Deep&nbsp;and&nbsp;Wide</Link>
                </li>
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/focused?title=ADA and Wheelchair Series&description=
    Easy to enter&description2=
    Ease of use when bathing&backgroundImg=WheelChairHome.png&leftBtnText=Order now&rightBtnText=Learn more'>
                    Wheelchair</Link>
                </li>

                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' 
                  to='/focused?title=Therapy Systems&description=&description2=Matching Faucet, glass door and pillow as added touch&backgroundImg=DeepAndWideHome.png&leftBtnText=Order now&rightBtnText=Learn more' >
                    Therapy</Link>
                </li>
                <li onClick={scrollTop} style={{ borderRadius: "8px" }} className="nav-item BTNTAB">
                  <Link style={{ textDecoration: "none", color: "black", fontSize: "larger" }} className='nav-link' to='/Accessories'>
                    Accessories</Link>
                </li>

                <li>
                  <div className='nav-link'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>

    </div>
  );
}

export default Header;

const CustomMenu = styled(MenuIcon)`
      cursor: pointer;
      color: black;
      `;

const BurgerNav = styled.div`
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      background: rgba(255, 255, 255, 1);
      width: 300px;
      z-index: 16;
      list-style: none;
      padding: 20px;
      display: flex;
      flex-direction: column;
      text-align: start;
      transform: ${(props) => (props.show ? "translateX(0)" : "translateX(100%)")};
      transition: transform 0.2s ease-in-out;

      overflow-y: scroll;

      li {
        padding: 15px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      a {
        color: black;
      font-weight: 200;
    }
  }

      ::-webkit-scrollbar {
        display: none;
  }
      `;

const CloseWrapper = styled.div`
      display: flex;
      justify-content: flex-end;
      `;

const CustomClose = styled(CloseIcon)`
      cursor: pointer;
      `;
