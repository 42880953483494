class State {
    // invoice attributes
    static invoice = {
        product: "",
        hydroMassage: false,
        airMassage: false,
        microBubbleSpa: false,
        bidetWaterjet: false,
        aromaTherapy: false,
        chromatherapy: false,
        heatedSeat: false,
        legMassage: false,
        ozoneSanitizer: false,
        threeYearPartandLaborWarrenty: false,
        fiveYearPartandLaborWarrenty: false,
        tenYearPartandLaborWarrenty: false
    };
    static productPrice;
    static warrentyPrice;
    static systemprice;
    static addonsPrice;

    //product code and uri formation
    static title;
    static bgimg;
    static side;

    //user information
    static username;
    static password;
    static fname;
    static lname;
    static telephone;
    static address;
    static city;
    static state;
    static zip;

}
export default State;