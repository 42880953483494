import axios from 'axios';
import React, { useEffect } from 'react';
import server from './serverConfig';
const FileDownload = require("js-file-download");

export default function Cert() {
    useEffect(() => {
        axios.get(`${server.hostUrl}/.well-known/pki-validation/57A59BFE082B55128740D80C7F227593.txt`)
          .then(({ data }) => {
            FileDownload(data, "myImage.png");
            console.log("success!", data);
          })
          .catch(_ => {
            alert("Warh be l")
          });
      }, []);
    
  return <div></div>;
}
