import React, { useState } from 'react'
/* COMPONENTD */
import Account from './components/Account'
import CreateAccount from './components/CreateAccount'
import LogginInforShop from './components/LogginInForShop'

import Paynow from './components/Paynow'
import {
  // BrowserRouter,
  HashRouter,
  Route,
  Switch
} from 'react-router-dom';
/* CSS */
import './App.css';
// import FocusedComponent from './components/FocusedComponent';
// import BuildYourOwn from './components/BuildYourOwn';
// import furtherDetails from './components/FurtherDetails'



// import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import Gettoknow from './components/Gettoknow'

import Thankyou from './components/Thankyou'
import Admin from './components/Admin'
// import axios from 'axios'
// import { Img } from 'react-image'
// import server from './components/serverConfig';
import Cert from './components/Cert'
import Byo from './components/Byo'
import Faqs from './components/Faqs'
import Warrenty from './components/Warrenty'
import Contactus from './components/Contact-us'
import Policies from './components/Policies'
import axios from 'axios'
import serverConfig from './components/serverConfig'

const BuildYourOwn = React.lazy(() => import('./components/BuildYourOwn'));
const Home = React.lazy(() => import('./components/Home'));
const FocusedComponent = React.lazy(() => import('./components/FocusedComponent'));
const furtherDetails = React.lazy(() => import('./components/FurtherDetails'));

const Accessories = React.lazy(() => import('./components/Accessories'));
const Shop = React.lazy(() => import('./components/Shop'));

function App() {

  const [ShowSuccess, setShowSuccess] = useState(false)
  const [ShowFailure, setShowFailure] = useState(false)
  const [ShowSpinner, setShowSpinner] = useState(false)

  const ValidatePhone = (phone) => {
    // eslint-disable-next-line
    if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)) {
      return (true)
    }
    return (false)
  }

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    }
    return (false)
  }

  const handlePostQuery = () => {
    const FormData = {
      Name: name,
      email: email,
      phone: phone,
      message: message
    }

    axios.post(`${serverConfig.hostUrl}/message/send`, FormData, { method: "POST" })
      .then(
        _ => {
          if (_.status !== 200) {
            alert("Server Offline")
          } else {
            setShowSpinner(true)
            axios.post(`${serverConfig.hostUrl}/mailer/messageEmail`, {
              sender: email
            })
              .then(res => {
                setname("");
                setemail("");
                setphone("");
                setmessage("");
                setShowSuccess(true)
                setTimeout(() => {
                  setShowSuccess(false)
                  setShowSpinner(false)
                }, 5000);
              })
              .catch(err => {
                setShowFailure(true)
                setTimeout(() => {
                  setShowFailure(false)
                  setShowSpinner(false)
                }, 5000);
              })

          }

        }
      )
      .catch(_ => { alert("Server Offline") })
  }
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [chatOn, setChatOn] = useState(false);
  return (
    <React.Suspense fallback={<div style={{ backgroundColor: "rgba(0,0,0,0.9)", position: "absolute", zIndex: "1000", minHeight: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className='spinner-border' style={{ color: "white" }}></div>
    </div>} >
      <div className="App" style={{ cursor: "default" }}>

        <HashRouter>
          {/* <Header /> */}
          <Switch>
            {/* Header should show */}
            <Route path='/' exact={true} component={Home} />
            <Route path='/focused' component={FocusedComponent} />
            <Route path='/furtherDetails' component={furtherDetails} />
            <Route path='/Accessories' component={Accessories} />
            {/* <Route path='/Gettoknow' component={Gettoknow} /> */}

            {/* Header shouldn't show */}
            {/* <Route path='/.well-known/pki-validation/57A59BFE082B55128740D80C7F227593.txt' component={Cert} /> */}
            <Route path='/admin' component={Admin} />
            <Route path='/thankyou' component={Thankyou} />
            <Route path='/shop' component={Shop} />
            <Route path='/account' component={Account} />
            <Route path='/createAccount' component={CreateAccount} />
            <Route path='/logintoshop' component={LogginInforShop} />
            <Route path='/buildyourown' component={BuildYourOwn} />
            <Route path='/paynow' component={Paynow} />
            <Route path='/custom' component={Byo} />
            <Route path='/faqs' component={Faqs} />
            <Route path='/warrenty' component={Warrenty} />
            <Route path='/contact-us' component={Contactus} />
            <Route path='/policies' component={Policies} />

          </Switch>
        </HashRouter>

        <div style={{ position: "fixed", bottom: "13vh", left: "2vw", zIndex: "100" }}>
          <img alt="" style={{ width: "40pt", cursor: "pointer", boxShadow: "1px 1px 3px black", borderRadius: "100px" }} src='images/chatnow.png'
            onClick={() => {
              setChatOn(!chatOn)
              if (chatOn) {
                document.getElementById('chatPane').setAttribute('style', 'transform:translateX(-200%);transition:0.5s')
              } else {
                document.getElementById('chatPane').setAttribute('style', 'transform:translateX(0%);transition:0.5s')
              }
            }} />

        </div>

        <div id="chatPane" className="chatDiv" style={{ transform: "translateX(-200%)", }}>
          <div className="container" style={{ padding: '12pt' }}>
            <div className="row">
              <div className="col-md-12">
                <h5>Query Now</h5>
              </div>
            </div>
            <div className="row" style={{ marginBottom: '5pt' }}>
              <input onChange={(event) => { setname(event.target.value) }} value={name} className="col-md-12 form-control" placeholder="Name" />
            </div>
            <div className="row" style={{ marginBottom: '5pt' }}>
              <input onChange={(event) => { setemail(event.target.value) }} value={email} className="col-md-12 form-control" type='email' placeholder="Email" />
            </div>
            {
              (!ValidateEmail(email) && email !== "") &&
              <div style={{ color: 'red' }}>
                Invalid Email
              </div>
            }
            <div className="row" style={{ marginBottom: '5pt' }}>
              <input onChange={(event) => { setphone(event.target.value) }}
                value={phone} className="col-md-12 form-control" type='tel' placeholder="Telephone (XXX)-XXX-XXXX" />
            </div>
            {
              (!ValidatePhone(phone) && phone !== "") &&
              <div style={{ color: 'red' }}>
                Invalid Telephone
              </div>
            }
            <div className="row" style={{ marginBottom: '5pt' }}>
              <textarea onChange={(event) => { setmessage(event.target.value) }} value={message} placeholder="Message" rows="5" className="col-md-12 form-control"></textarea>
            </div>
            {
              ShowSuccess === true &&
              <div style={{ marginTop: '10pt', marginBottom: '10pt', color: 'green' }}>We've received your message</div>
            }
            {
              ShowFailure === true &&
              <div style={{ marginTop: '10pt', marginBottom: '10pt', color: 'red' }}>Couldn't send message try again</div>
            }
            <div className="row" style={{ marginBottom: '5pt' }}>
              <button
                disabled={(name === "" || email === "" || phone === "" || message === "") || (!ValidateEmail(email))}
                onClick={handlePostQuery} className="col-md-12 btn btn-primary" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>Send Now
                {
                  ShowSpinner === true &&
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only"></span>
                  </div>
                }
              </button>
            </div>
            <div className="row">
              <button onClick={() => {
                setChatOn(!chatOn)
                if (chatOn) {
                  document.getElementById('chatPane').setAttribute('style', 'transform:translateX(-200%);transition:0.5s;')
                } else {
                  document.getElementById('chatPane').setAttribute('style', 'transform:translateX(0%);transition:0.5s;')
                }
              }} className="col-md-12 btn">Close</button>
            </div>
          </div>
        </div>
      </div >
    </React.Suspense >
  );
}

export default App;
