import React, {useEffect} from 'react'
import Footer from './Footer'
import Header from './Header'
import Testimonials from './testimonials'

function Policies() {
    useEffect(() => {
    window.scrollTo(0,0)
    
      return () => {
      
      }
    }, [])
    
    return (
        <div>
            <Header />
            <div style={{height:'10vh'}}></div>
            <div style={{ paddingTop: '15vh' }}>
                <div className='container'>
                    <h3>Shipping + Return Policy </h3>
                    <br />
                    <h4>Shipping Information </h4>
                    <br />
                    <p>Please note: Energy Tubs will ship product within 3 — 5 days upon receipt of full payment, physical address, and phone number. </p>
                    <p>All tubs will be shipped via LTL freight carriers to the Contiguous 48 United States_ Please note that some rural areas, inaccessible roads or limited access for semi-trucks (steep hill, gravel road, long driveway, narrow road, private road, etc.) might not be deliverable by our contracted carriers and additional shipping cost may be incurred or other pick up arrangements will need to be made</p>
                    <p>Energy Tubs will not ship to a PG Box, APO or FPO, Canada, and US Protectorates / Territories (Guam, Puerto Rico, Virgin Islands). Please contact Energy Tubs for shipping quotes to Alaska and Hawaii Please contact Energy Tubs for details before you make your purchase if any of the conditions above apply to your location. The freight delivery company will contact you to schedule delivery of your product. Energy Tubs will send your tracking number within 24 hours upon shipment or your product.</p>

                    <br />
                    <br />
                    <h3>Receiving Note</h3>
                    <br />
                    <h5>Delivery is curbside only!</h5>
                    <h4>Shipping Information</h4>
                    <br />
                    <p>THIS IS VERY IMPORTANT: At the time of receiving delivery, open the container and inspect the entire contents of the package to make sure there is no damage or missing parts to your product Be sure to indicate any damage you find during your inspection on the delivery receipt and reject the products. This will allow Energy Tubs to replace any items that are damaged by the freight carrier at no cost to you. If the container is not inspected at time of delivery and/or if it is accepted by the receiver and the product is found to be damaged, the buyer will be responsible for any replacement cost </p>
                    <p>Please note this is a very large and heavy When the delivery has been scheduled, please make arrangements to have the product moved from the street onto your property in a safe place until installation</p>
                    <br />
                    <br />
                    <h3>Returning Policy</h3>
                    <br />
                    <h4 style={{ textTransform: 'uppercase' }}>If your item returns broken then please follow the instructions below</h4>
                    <br />
                    
                    <p>Inspect all shipments upon arrival and before signing the delivery receipts. if there is damage, you must refuse the shipment and describe the damage on the delivery receipt. Contact Energy Tubs FOR AN RA and a replacement will be shipped out<br/>
                    If damage is found after the shipment has been accepted and no damage is noted on the receipt, Energy Tubs reserves the right NOT to refund or replace the damaged item/items. 
                    <br/>Please be sure to keep all the original packaging for any returns</p>

                    <br />
                    <br />
                    <h4>Returns and Cancellation not due to damage:</h4>
                    <br />
                    <p>Please contact Energy Tubs within 30 days from the date received to request an RA number. There will be a 35% restocking fee for returned merchandise. Please note that the buyer is responsible for all shipping and handling charges to return the product <br/>
                    Once the item is returned and inspected, a credit will be given to the buyer by form of payment given at initial purchase
                    </p>

                    <h3>No installed items will be returned</h3>
                    {/* Footer for this details kind of page */}
                    <br />
                    <hr />
                    <Testimonials />

                    <br />
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Policies