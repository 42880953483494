import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import "./Byo.css";
import Header from "./Header";
import headerLogo from "./img/etslogo2.png";
import "bootstrap/dist/css/bootstrap.min.css";

// "?pid=N(0...3) or even null or undefined"
export class Byo extends Component {
  floatingCarouselMap = [];
  toggleCarousel = () => {
    this.updateCarousel();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (this.state.caurselOpen) {
      document
        .getElementById("myCarousel")
        .setAttribute(
          "style",
          "transition:1s;position:fixed;transform:translateY(-100%) scale(0.3) ;height:100vh;width:100%;background-color:rgba(0,0,0,0.0);"
        );
      this.setState({ caurselOpen: false });
    } else {
      document
        .getElementById("myCarousel")
        .setAttribute(
          "style",
          "transition:0.5s;position:fixed;translateY(0%);height:100vh;width:100%;background-color:rgba(0,0,0,0.1);"
        );
      this.setState({ caurselOpen: true });
    }
  };
  DoesExists = (text) => {
    // Following two checks disables overlay over 3138 and 3138W
    // if (this.state.products[this.state.productID].title === "Compact Series") {
    //     if (this.state.chosenItem.find(it => it.type === "type1") !== undefined) {
    //         if (this.state.chosenItem.find(it => it.type === "type1").name === "ET3138") {
    //             return false
    //         }
    //     }
    // }
    // if (this.state.products[this.state.productID].title === "Wheelchair Series") {
    //     if (this.state.chosenItem.find(it => it.type === "type1") !== undefined) {
    //         if (this.state.chosenItem.find(it => it.type === "type1").name === "ET3138W") {
    //             return false
    //         }
    //     }
    // }
    return this.state.chosenItem.findIndex((ci) => ci.name === text) !== -1;
  };
  ImageOrientation = ["TOP", "SIDE", "DIAGONAL"];
  CarouselInterval = null;
  constructor(props) {
    super(props);
    let search = this.props.location.search;
    let id =
      new URLSearchParams(search).get("pid") === "undefined" ||
      new URLSearchParams(search).get("pid") === " " ||
      new URLSearchParams(search).get("pid") === "" ||
      new URLSearchParams(search).get("pid") === "." ||
      new URLSearchParams(search).get("pid") === null
        ? 0
        : new URLSearchParams(search).get("pid");
    let pidTemp = 0;
    pidTemp = Number.parseInt(id) % 4;
    let otherOptions = [
      {
        section: "FeatureDetails",
        subtitle: "",
        variant: [],
      },
      {
        section: "2. Select door opening",
        subtitle: "",
        variant: [
          {
            name: `lh`,
            dimensions: "",
            price: "",
            type: "type3",
          },
          {
            name: `rh`,
            dimensions: "",
            price: "",
            type: "type3",
          },
        ],
      },
      {
        section: "3. System",
        subtitle: "",
        variant: [
          {
            name: `Air Massage`,
            dimensions: ``,
            price: 897,
            type: "type5",
          },
          {
            name: `Hydro Massage (Syllent)`,
            dimensions: ``,
            price: 897,
            type: "type5",
          },
          {
            name: `Micro Bubble System`,
            dimensions: ``,
            price: 1497,
            type: "type5",
          },
        ],
      },
      {
        section: "4. Add-ons",
        subtitle: "",
        variant: [
          {
            name: `Bidet Spa System`,
            dimensions: ``,
            price: 197,
            type: "type4",
          },
          // {
          //     name: `In-line Heater`,
          //     dimensions: ``,
          //     price: 397,
          //     type: "type4",
          // },
          {
            name: `Aroma Therapy`,
            dimensions: ``,
            price: 275,
            type: "type4",
          },
          {
            name: `Chroma Therapy`,
            dimensions: ``,
            price: 575,
            type: "type4",
          },
          {
            name: `Ozone Sanitizer`,
            dimensions: ``,
            price: 475,
            type: "type4",
          },
          {
            name: `Heated Seat`,
            dimensions: ``,
            price: 475,
            type: "type4",
          },
          {
            name: `Leg Massage`,
            dimensions: ``,
            price: 275,
            type: "type4",
          },
        ],
      },
      {
        section: "5. warranty & Installation",
        subtitle: "",
        variant: [
          {
            name: `3 year parts & labor warranty`,
            dimensions: ``,
            price: 399,
            type: "type2",
          },
          {
            name: `5 year parts & labor warranty`,
            dimensions: ``,
            price: 499,
            type: "type2",
          },
          {
            name: `10 year parts & labor warranty`,
            dimensions: ``,
            price: 799,
            type: "type2",
          },
        ],
      },
    ];

    this.state = {
      touched: false,
      carouselTubIndex: 0,
      showInvoice: false,
      caurselOpen: false,
      imageOrientationIndex: 0,
      side:
        localStorage.getItem("DOOR_OPENNING") !== null
          ? localStorage.getItem("DOOR_OPENNING")
          : "lh",
      productID: pidTemp,
      chosenItem:
        localStorage.getItem("CART_ITEMS") !== null
          ? JSON.parse(
              JSON.stringify(JSON.parse(localStorage.getItem("CART_ITEMS")))
            )
          : [],
      products: [
        {
          title: "Compact Series",
          options: [
            {
              section: "1. Tub",
              subtitle: `BASE MODEL (W") x (L") x (H")`,
              variant: [
                {
                  name: `ET2646-S`,
                  dimensions: `26"x46"xw/14"`,
                  price: 2997,
                  type: "type1",
                },
                {
                  name: `ET2653`,
                  dimensions: `26"x53"xw/7"`,
                  price: 2997,
                  type: "type1",
                },
                {
                  name: `ET2848`,
                  dimensions: `28"x48"xw/12"`,
                  price: 2997,
                  type: "type1",
                },
                {
                  name: `ET3138`,
                  dimensions: `32"x38"`,
                  price: 3997,
                  type: "type1",
                },
              ],
            },
            ...otherOptions,
          ],
        },
        {
          title: "Standard Series",
          options: [
            {
              section: "1. Tub",
              subtitle: `BASE MODEL (W") x (L") x (H")`,
              variant: [
                {
                  name: `ET2852`,
                  dimensions: `28"x52"xw/8"`,
                  price: 2997,
                  type: "type1",
                },
                {
                  name: `ET3052`,
                  dimensions: `30"x52"xw/8"`,
                  price: 2997,
                  type: "type1",
                },
                // {
                //     name: `ET3054`,
                //     dimensions: `30"x54"xw/6"`,
                //     price: 2997,
                //     type: "type1",
                // },
                {
                  name: `ET3060`,
                  dimensions: `30"x60"`,
                  price: 2997,
                  type: "type1",
                },
              ],
            },
            ...otherOptions,
          ],
        },
        {
          title: "Deep and Wide Series",
          options: [
            {
              section: "1. Tub",
              subtitle: `BASE MODEL (W") x (L") x (H")`,
              variant: [
                {
                  name: `ET3355`,
                  dimensions: `33"x55"xw/5"`,
                  price: 3997,
                  type: "type1",
                },
                {
                  name: `ET3555`,
                  dimensions: `35"x55"xw/5"`,
                  price: 3997,
                  type: "type1",
                },
                {
                  name: `ET3055`,
                  dimensions: `30"x55"x47"Hxw/5"`,
                  price: 3997,
                  type: "type1",
                },
              ],
            },
            ...otherOptions,
          ],
        },
        {
          title: "Wheelchair Series",
          options: [
            {
              section: "1. Tub",
              subtitle: `BASE MODEL (W") x (L") x (H")`,
              variant: [
                {
                  name: `ET3138W`,
                  dimensions: `32"x38"`,
                  price: 3997,
                  type: "type1",
                },
                {
                  name: `ET2852W`,
                  dimensions: `28"x52"xw/8"`,
                  price: 3997,
                  type: "type1",
                },
                {
                  name: `ET3052W`,
                  dimensions: `30"x52"xw/8"`,
                  price: 3997,
                  type: "type1",
                },
                {
                  name: `ET3252W`,
                  dimensions: `32"x52"xw/8"`,
                  price: 3997,
                  type: "type1",
                },
              ],
            },
            ...otherOptions,
          ],
        },
      ],
    };
    // this.CarouselInterval = setInterval(() => {
    //   if (
    //     this.state.carouselTubIndex <
    //     this.state.products[this.state.productID].options[0].variant.length - 1
    //   ) {
    //     this.setState({ carouselTubIndex: this.state.carouselTubIndex + 1 });
    //   } else {
    //     this.setState({ carouselTubIndex: 0 });
    //   }
    // }, 5000);
  }
  InitateIntervalAllOverAgain = () => {
    clearInterval(this.CarouselInterval);
    this.CarouselInterval = setInterval(() => {
      if (
        this.state.carouselTubIndex <
        this.state.products[this.state.productID].options[0].variant.length - 1
      ) {
        this.setState({ carouselTubIndex: this.state.carouselTubIndex + 1 });
      } else {
        this.setState({ carouselTubIndex: 0 });
      }
    }, 5000);
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      chosenItem: [],
    });
    this.handleIMageShowUpponChangeTub();
  }
  updateCarousel = () => {
    this.floatingCarouselMap = [
      {
        title: this.state.products[this.state.productID].title,
        Description:
          this.state.products[this.state.productID].title === "Compact Series"
            ? "Equipped for small spaces, this tub does not sacrifice comfort for footprint. Compact Walk-in Bathtubs Designed to Fit 60-inch Cutaways. Inward-Opening with Waterproof Door Seal. Curved and Comfortable Seating"
            : this.state.products[this.state.productID].title ===
              "Standard Series"
            ? "Great Models that fit most bathroom layouts. Standard Walk-in Bathtubs Designed to Fit 60-inch Cutaways. Inward-Opening with Waterproof Door Seal. Have peace of mind knowing your safety is covered. Designed specifically for your comfort."
            : this.state.products[this.state.productID].title ===
              "Deep and Wide Series"
            ? "Well equipped for deep soaking bathing experiences. Deep & Wide Walk-in Bathtubs Designed to Fit 60-inch Cutaways. Inward-Opening with Waterproof Door Seal. Curved and Comfortable Seating. Designed specifically for your comfort."
            : this.state.products[this.state.productID].title ===
              "Wheelchair Series"
            ? "Easy to enter and exit allowing ease of use when bathing. A.D.A. Walk-in Bathtubs Designed to Fit 60-inch Cutaways. A.D.A. compliant seating with easy access doorway. Curved and Comfortable Seating. Designed specifically for your comfort."
            : "Default Description",
        Image: "",
      },
      {
        title: "",
        Description:
          "The massage system is designed to hit key pressure points, briskly stimulating aching muscles and joints and promoting a satisfying massaging experience that provides relief from pain and stress.",
        Image: "",
      },
      {
        title: "Engineered for better bathing experience",
        Description:
          "Energy Tubs walk-in baths are products specifically designed and manufactured with the seniors and the handicapped in mind. The tub comes equipped with built-in features addressing the safety concerns of the physically informed so that bathing will be enjoyable and risk-free. Essential safety features of a walk in tub product.",
        Image: "",
      },
    ];
  };
  CalculateCartPrice = () => {
    let sum = 0;
    this.state.chosenItem.forEach((item) => {
      sum += item.price;
    });
    return sum;
  };
  disableType = (type) => {
    for (let index = 0; index < this.state.chosenItem.length; index++) {
      const element = this.state.chosenItem[index];
      if (element.type === type) {
        this.setState({
          chosenItem: [...this.state.chosenItem.splice(index, 1)],
        });
        return;
      }
    }
  };
  handleShow = () => {
    this.containsTubs();
    document.getElementById("invoiceModalContainer").style.transform =
      "translateY(0%)";
    document.getElementById("invoiceModalContainer").style.transition = "0.5s";
    document.getElementById("invoiceModal").style.transition = "1s";
    document.getElementById("invoiceModal").style.transform = "scale(1)";
  };
  handleClose = () => {
    document.getElementById("invoiceModalContainer").style.transform =
      "translateY(-100%)";
    document.getElementById("invoiceModalContainer").style.transition = "1.5s";
    document.getElementById("invoiceModal").style.transition = "0.5s";
    document.getElementById("invoiceModal").style.transform = "scale(0)";
  };
  sortedInvoice = () => {
    let TObesorted = JSON.parse(JSON.stringify(this.state.chosenItem));
    for (let index = 0; index < TObesorted.length; index++) {
      for (let index2 = index + 1; index2 < TObesorted.length; index2++) {
        if (TObesorted[index].price < TObesorted[index2].price) {
          let temp = JSON.parse(JSON.stringify(TObesorted[index]));
          TObesorted[index] = JSON.parse(JSON.stringify(TObesorted[index2]));
          TObesorted[index2] = JSON.parse(JSON.stringify(temp));
        }
      }
    }
    return TObesorted;
  };
  handleSendDataToPayment = () => {
    localStorage.setItem("DOOR_OPENNING", this.state.side);
    localStorage.setItem("CART_ITEMS", JSON.stringify(this.state.chosenItem));
    localStorage.setItem(
      "TUB_IMAGE",
      `/IMAGE/${this.state.products[this.state.productID].title}/${
        this.state.chosenItem.find((ci) => ci.type === "type1").name
      }/${this.ImageOrientation[this.state.imageOrientationIndex]}.png`
    );
  };
  deleteCacheCart = () => {
    localStorage.removeItem("CART_ITEMS");
    localStorage.removeItem("DOOR_OPENNING");
    localStorage.removeItem("TUB_IMAGE");
  };
  containsTubs = () => {
    return this.state.chosenItem.findIndex((ci) => ci.type === "type1");
  };
  handleIMageShowUpponChangeTub = () => {
    this.setState({
      chosenItem: [
        this.state.products.find(
          (title) =>
            title.title === this.state.products[this.state.productID].title
        ).options[0].variant[0],
      ],
    });
  };
  TubExists = () => {
    for (let index = 0; index < this.state.chosenItem.length; index++) {
      const element = this.state.chosenItem[index];
      if (element.type !== undefined) {
        if (element.type === "type1") {
          return true;
        }
      }
    }
    return false;
  };
  GetStylesFor = (series, tub, option) => {
    // apply rulesets for each series' tub's option
    if (
      series === "Compact Series" &&
      tub === "ET2646-S" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: -20,
        marginRight: 0,
        marginTop: 63,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2646-S" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: -30,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2646-S" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 10,
        marginRight: 0,
        marginTop: -5,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2646-S" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 30,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2653" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: -20,
        marginRight: 0,
        marginTop: 42,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2653" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: -30,
        marginRight: 0,
        marginTop: -12,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2848" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 12,
        marginRight: 0,
        marginTop: 40,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET2848" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 8,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: -10,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET2852" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -33,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: -20,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3052" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: -43,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Standard Series" &&
      tub === "ET3060" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -42,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 60,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 50,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 45,
        marginRight: 0,
        marginTop: 68,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: -17,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3355" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 55,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 55,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 50,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 60,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: -20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3555" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: -65,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: -75,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -70,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -110,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -110,
        marginBottom: 0,
      };
    }
    if (
      series === "Deep and Wide Series" &&
      tub === "ET3055" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -110,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 45,
        marginRight: 0,
        marginTop: -10,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 35,
        marginRight: 0,
        marginTop: -20,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 55,
        marginRight: 0,
        marginTop: -60,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 15,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET2852W" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 60,
        marginRight: 0,
        marginTop: -40,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: -35,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 35,
        marginRight: 0,
        marginTop: 10,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 60,
        marginRight: 0,
        marginTop: 10,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 30,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Heated Seat"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -50,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3052W" &&
      option === "Leg Massage"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: -55,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 15,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Air Massage"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 15,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Hydro Massage (Syllent)"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 15,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 20,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 40,
        marginRight: 0,
        marginTop: 60,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Aroma Therapy"
    ) {
      return {
        marginLeft: 65,
        marginRight: 0,
        marginTop: -20,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3252W" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 20,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET3138" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 115,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET3138" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 30,
        marginBottom: 0,
      };
    }
    if (
      series === "Compact Series" &&
      tub === "ET3138" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 180,
        marginRight: 0,
        marginTop: 40,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3138W" &&
      option === "Micro Bubble System"
    ) {
      return {
        marginLeft: 115,
        marginRight: 0,
        marginTop: -30,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3138W" &&
      option === "Bidet Spa System"
    ) {
      return {
        marginLeft: 50,
        marginRight: 0,
        marginTop: 30,
        marginBottom: 0,
      };
    }
    if (
      series === "Wheelchair Series" &&
      tub === "ET3138W" &&
      option === "Chroma Therapy"
    ) {
      return {
        marginLeft: 180,
        marginRight: 0,
        marginTop: 40,
        marginBottom: 0,
      };
    }

    return {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
    };
  };
  render() {
    return (
      <React.Suspense
        fallback={
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0.9)",
              position: "absolute",
              zIndex: "1000",
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="spinner-border" style={{ color: "white" }}></div>
          </div>
        }
      >
        <div
          id="invoiceModalContainer"
          style={{
            height: "100vh",
            width: "100%",
            position: "fixed",
            zIndex: "1040",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(-100%)",
          }}
        >
          <div id="invoiceModal" className="invoiceModal">
            <Modal.Title
              style={{
                color: "gray",
                fontWeight: "lighter",
                width: "100%",
                marginTop: "3vh",
              }}
            >
              <div className="row" style={{ width: "100%" }}>
                <div className="col-7">
                  <img alt="" style={{ width: "130pt" }} src={headerLogo} />
                </div>
                <div className="col-5">Invoice Details</div>
              </div>
            </Modal.Title>
            <Modal.Body>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <td>ITEM</td>
                    <td>PRICE</td>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "small" }}>
                  {this.sortedInvoice().map((it) => (
                    <tr key={it.name + it.price}>
                      <td>{it.name}</td>
                      <td>$ {it.price}</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    <td>$ {this.CalculateCartPrice()}</td>
                  </tr>
                </tbody>
              </table>
              <div style={{ textAlign: "right" }}>
                <div className="btn-group">
                  <button
                    onClick={() => {
                      this.handleClose();
                    }}
                    className="focusedBLK btn"
                    style={{
                      backgroundColor: "rgba(13,13,13,0.9)",
                      color: "white",
                    }}
                  >
                    Close
                  </button>
                  {this.CalculateCartPrice() !== 0 && this.TubExists() && (
                    <Link
                      to="/paynow"
                      onClick={() => {
                        this.handleSendDataToPayment();
                      }}
                      className="focusedGRN btn"
                      style={{
                        backgroundColor: "rgba(16,82,134,0.9)",
                        color: "white",
                      }}
                    >
                      Checkout
                    </Link>
                  )}
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
        <div
          id="myCarousel"
          className="myCarouselClass"
          style={{
            transition: "1s",
            position: "fixed",
            transform: "translateY(-100%) scale(0.3)",
            height: "100vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.0)",
          }}
        >
          <table style={{ minHeight: "100vh" }}>
            <tbody>
              <tr>
                <td>
                  <div className="floatingModelTransparent">
                    <Img
                      style={{ width: "50pt", transform: "rotate(90deg)" }}
                      onClick={() => {}}
                      src="/images/down-arrow.svg"
                    />
                  </div>
                </td>
                {this.floatingCarouselMap.map((data, index) => {
                  return (
                    <td key={data.title}>
                      <div className="floatingModel">
                        <button
                          className="btn "
                          style={{
                            borderRadius: "50%",
                            position: "absolute",
                            fontSize: "2vh",
                          }}
                          onClick={this.toggleCarousel}
                        >
                          x
                        </button>
                        <div className="row" style={{ height: "100%" }}>
                          <div
                            className="col-xl-6"
                            style={{
                              height: "100%",
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              padding: "30pt",
                            }}
                          >
                            <img
                              style={{ height: "100%", width: "100%" }}
                              src={`/IMAGE/${
                                this.state.products[this.state.productID].title
                              }/${
                                this.state.chosenItem.find(
                                  (ci) => ci.type === "type1"
                                ).name
                              }/${
                                index === 0
                                  ? "mOpenning"
                                  : index === 1
                                  ? "mSeat"
                                  : "mTop"
                              }.jpeg`}
                              alt={"new logo"}
                            />
                          </div>
                          <div
                            className="col-xl-6"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="container">
                              <h5 style={{ fontWeight: "bold" }}>
                                {data.title.toUpperCase()}
                              </h5>
                              <p style={{ fontSize: "12pt" }}>
                                {data.Description}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  );
                })}
                <td>
                  <div className="floatingModelTransparent">
                    <Img
                      style={{ width: "50pt", transform: "rotate(270deg)" }}
                      onClick={() => {}}
                      src="/images/down-arrow.svg"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="parent" className="parent">
          <Header />
          <div className="lchild">
            {
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.touched === true &&
                this.state.chosenItem.find((it) => it.type === "type1") !==
                  undefined && ( //true || //this true keeps that select tub promt hidden
                    <>
                      <button
                        className="btn btn-outline-light text-black"
                        hidden={this.state.imageOrientationIndex === 0}
                        onClick={() => {
                          if (this.state.imageOrientationIndex > 0) {
                            this.setState({
                              imageOrientationIndex:
                                this.state.imageOrientationIndex - 1,
                            });
                          }
                          this.InitateIntervalAllOverAgain();
                        }}
                        style={{
                          width: "30pt",
                          height: "30pt",
                          boxShadow: "0pt 10pt 20pt rgb(180,180,180)",
                          borderRadius: "20pt",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "40pt",
                        }}
                      >
                        <Img
                          style={{
                            transform: "rotate(90deg)",
                            width: "25pt",
                            height: "25pt",
                          }}
                          src="/images/down-arrowb.svg"
                        />
                      </button>
                      <div
                        style={{
                          height: "100vh",
                          width: window.innerWidth / 2.5,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            position: "absolute",
                            left: 0,
                          }}
                          src={`/IMAGE/${
                            this.state.products[this.state.productID].title
                          }/${
                            this.state.chosenItem.find(
                              (it) => it.type === "type1"
                            ).name
                          }/${
                            this.ImageOrientation[
                              this.state.imageOrientationIndex
                            ] !== "DIAGONAL"
                              ? this.ImageOrientation[
                                  this.state.imageOrientationIndex
                                ]
                              : this.state.side === "lh"
                              ? this.ImageOrientation[
                                  this.state.imageOrientationIndex
                                ] + "L"
                              : this.ImageOrientation[
                                  this.state.imageOrientationIndex
                                ] + "R"
                          }.png`}
                          alt="Tub logo"
                        />
                        {this.DoesExists("Air Massage") && (
                          <>
                            {this.state.chosenItem.find(
                              (it) => it.type === "type1"
                            ).name !== "ET3138" &&
                              this.state.chosenItem.find(
                                (it) => it.type === "type1"
                              ).name !== "ET3138W" && (
                                <img
                                  style={{
                                    width: "100%",
                                    position: "absolute",
                                    left: 0,
                                    marginLeft: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Air Massage"
                                    ).marginLeft,
                                    marginRight: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Air Massage"
                                    ).marginRight,
                                    marginTop: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Air Massage"
                                    ).marginTop,
                                    marginBottom: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Air Massage"
                                    ).marginBottom,
                                  }}
                                  src={`/IMAGE/options/AirMassage.png`}
                                  alt="Tub logo"
                                />
                              )}
                            {(this.state.chosenItem.find(
                              (it) => it.type === "type1"
                            ).name === "ET3138" ||
                              this.state.chosenItem.find(
                                (it) => it.type === "type1"
                              ).name === "ET3138W") && (
                              <img
                                style={{
                                  width: "40%",
                                  position: "absolute",
                                  left: 0,
                                  height: "27%",
                                  marginLeft: "175pt",
                                  marginBottom: "30pt",
                                }}
                                src={`/IMAGE/options/AirMassage3138.png`}
                                alt="Tub logo"
                              />
                            )}
                          </>
                        )}
                        {this.DoesExists("Chroma Therapy") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Chroma Therapy"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Chroma Therapy"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Chroma Therapy"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Chroma Therapy"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/Chromatherapy.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Aroma Therapy") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Aroma Therapy"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Aroma Therapy"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Aroma Therapy"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Aroma Therapy"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/Aromatherapy.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Bidet Spa System") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Bidet Spa System"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Bidet Spa System"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Bidet Spa System"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Bidet Spa System"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/Bidetjet.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Heated Seat") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Heated Seat"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Heated Seat"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Heated Seat"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Heated Seat"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/HeatedSeats.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Hydro Massage (Syllent)") && (
                          <>
                            {this.state.chosenItem.find(
                              (it) => it.type === "type1"
                            ).name !== "ET3138" &&
                              this.state.chosenItem.find(
                                (it) => it.type === "type1"
                              ).name !== "ET3138W" && (
                                <img
                                  style={{
                                    width: "100%",
                                    position: "absolute",
                                    left: 0,
                                    marginLeft: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Hydro Massage (Syllent)"
                                    ).marginLeft,
                                    marginRight: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Hydro Massage (Syllent)"
                                    ).marginRight,
                                    marginTop: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Hydro Massage (Syllent)"
                                    ).marginTop,
                                    marginBottom: this.GetStylesFor(
                                      this.state.products[this.state.productID]
                                        .title,
                                      this.state.chosenItem.find(
                                        (it) => it.type === "type1"
                                      ).name,
                                      "Hydro Massage (Syllent)"
                                    ).marginBottom,
                                  }}
                                  src={`/IMAGE/options/HydroMassage.png`}
                                  alt="Tub logo"
                                />
                              )}
                            {(this.state.chosenItem.find(
                              (it) => it.type === "type1"
                            ).name === "ET3138" ||
                              this.state.chosenItem.find(
                                (it) => it.type === "type1"
                              ).name === "ET3138W") && (
                              <img
                                style={{
                                  width: "40%",
                                  position: "absolute",
                                  left: 0,
                                  height: "27%",
                                  marginLeft: "170pt",
                                  marginBottom: "27pt",
                                }}
                                src={`/IMAGE/options/HydroMassage3138.png`}
                                alt="Tub logo"
                              />
                            )}
                          </>
                        )}
                        {this.DoesExists("Leg Massage") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Leg Massage"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Leg Massage"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Leg Massage"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Leg Massage"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/LegMassage.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Micro Bubble System") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Micro Bubble System"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Micro Bubble System"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Micro Bubble System"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Micro Bubble System"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/Microbubbles.png`}
                            alt="Tub logo"
                          />
                        )}
                        {this.DoesExists("Ozone Sanitizer") && (
                          <img
                            style={{
                              width: "100%",
                              position: "absolute",
                              left: 0,
                              marginLeft: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Ozone Sanitizer"
                              ).marginLeft,
                              marginRight: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Ozone Sanitizer"
                              ).marginRight,
                              marginTop: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Ozone Sanitizer"
                              ).marginTop,
                              marginBottom: this.GetStylesFor(
                                this.state.products[this.state.productID].title,
                                this.state.chosenItem.find(
                                  (it) => it.type === "type1"
                                ).name,
                                "Ozone Sanitizer"
                              ).marginBottom,
                            }}
                            src={`/IMAGE/options/OzoneSanitizer.png`}
                            alt="Tub logo"
                          />
                        )}
                      </div>
                      {/* <button
                        className="btn btn-outline-light text-black"
                        hidden={
                          this.state.imageOrientationIndex ===
                          this.ImageOrientation.length - 1
                        }
                        onClick={() => {
                          if (
                            this.state.imageOrientationIndex <
                            this.ImageOrientation.length - 1
                          ) {
                            this.setState({
                              imageOrientationIndex:
                                this.state.imageOrientationIndex + 1,
                            });
                          }
                          this.InitateIntervalAllOverAgain();
                        }}
                        style={{
                          width: "30pt",
                          height: "30pt",
                          boxShadow: "0pt 10pt 20pt rgb(180,180,180)",
                          borderRadius: "20pt",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginLeft: "40pt",
                        }}
                      >
                        <Img
                          style={{
                            transform: "rotate(270deg)",
                            width: "25pt",
                            height: "25pt",
                          }}
                          src="/images/down-arrowb.svg"
                        />
                      </button> */}
                    </>
                  )}

                {this.state.touched === false && (
                  <>
                    {/* <button
                      className="btn btn-outline-light text-black"
                      hidden={this.state.carouselTubIndex === 0}
                      onClick={() => {
                        this.InitateIntervalAllOverAgain();
                        if (this.state.carouselTubIndex >= 0) {
                          this.setState({
                            carouselTubIndex: this.state.carouselTubIndex - 1,
                          });
                        } else {
                          this.setState({ carouselTubIndex: 0 });
                        }
                      }}
                      style={{
                        width: "30pt",
                        height: "30pt",
                        boxShadow: "0pt 10pt 20pt rgb(180,180,180)",
                        borderRadius: "20pt",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "40pt",
                      }}
                    >
                      <Img
                        style={{
                          transform: "rotate(90deg)",
                          width: "25pt",
                          height: "25pt",
                        }}
                        src="/images/down-arrowb.svg"
                      />
                    </button> */}
                    <div
                      style={{
                        height: "100vh",
                        width: window.innerWidth / 2.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "100%" }}
                        src={`/IMAGE/${
                          this.state.products[this.state.productID].title
                        }/${
                          this.state.products[this.state.productID].options[0]
                            .variant[this.state.carouselTubIndex].name
                        }/${"TOP"}.png`}
                        alt="Tub logo"
                      />
                    </div>
                    {/* <button
                      className="btn btn-outline-light text-black"
                      hidden={
                        this.state.carouselTubIndex ===
                        this.state.products[this.state.productID].options[0]
                          .variant.length -
                          1
                      }
                      onClick={() => {
                        this.InitateIntervalAllOverAgain();
                        if (
                          this.state.carouselTubIndex <
                          this.state.products[this.state.productID].options[0]
                            .variant.length -
                            1
                        ) {
                          this.setState({
                            carouselTubIndex: this.state.carouselTubIndex + 1,
                          });
                        } else {
                          this.setState({ carouselTubIndex: 0 });
                        }
                      }}
                      style={{
                        width: "30pt",
                        height: "30pt",
                        boxShadow: "0pt 10pt 20pt rgb(180,180,180)",
                        borderRadius: "20pt",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "40pt",
                      }}
                    >
                      <Img
                        style={{
                          transform: "rotate(270deg)",
                          width: "25pt",
                          height: "25pt",
                        }}
                        src="/images/down-arrowb.svg"
                      />
                    </button> */}
                  </>
                )}
              </div>
            }
            {this.state.chosenItem.find((it) => it.type === "type1") ===
              undefined &&
            this.state.touched && ( //this false keeps that select tub promt hidden
                <div>
                  <div style={{ fontSize: "4vh", textAlign: "center" }}>
                    Please select the tub from the menu
                  </div>
                </div>
              )}

            <div
              className="bottomBar"
              style={{ backgroundColor: "rgb(220,220,220)" }}
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      {this.DoesExists("Hydro Massage (Syllent)") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Hydro.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Air Massage") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Air.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Micro Bubble System") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Micro.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Bidet Spa System") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Bidet.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Aroma Therapy") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Aroma.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Chroma Therapy") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Chroma.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Heated Seat") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Heated.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Leg Massage") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Leg.png"
                        />
                      )}
                    </td>
                    <td>
                      {this.DoesExists("Ozone Sanitizer") && (
                        <img
                          alt=""
                          style={{ height: "7vh" }}
                          src="images/options/Ozone.png"
                        />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "5px" }}>
                    {" "}
                    View Your Invoice:
                  </span>
                  <button
                    className="btn btn-outline-light text-black"
                    onClick={this.handleShow}
                    style={{
                      width: "30pt",
                      height: "30pt",
                      boxShadow: "0pt 10pt 20pt rgb(180,180,180)",
                      borderRadius: "20pt",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Img
                      style={{
                        transform: "rotate(180deg)",
                        width: "25pt",
                        height: "25pt",
                      }}
                      src="/images/down-arrowb.svg"
                    />
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "2.5vh",
                    fontWeight: "300",
                    color: "black",
                  }}
                >
                  Total: ${this.CalculateCartPrice()}
                </div>
              </div>
            </div>
          </div>
          <div className="spacer_lchild"></div>

          <div
            className="rchild"
            style={{
              paddingTop: "20vh",
              paddingLeft: "15pt",
              paddingRight: "15pt",
            }}
          >
            <div className="container center">
              <h5 style={{ color: "gray", fontWeight: "300" }}>
                Build your own tub
              </h5>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Img
                  style={{ width: "30pt", transform: "rotate(90deg)" }}
                  onClick={() => {
                    if (this.state.productID > 0) {
                      this.setState({
                        productID: this.state.productID - 1,
                      });
                      this.setState({
                        chosenItem: [
                          this.state.products.find(
                            (title) =>
                              title.title ===
                              this.state.products[this.state.productID - 1]
                                .title
                          ).options[0].variant[0],
                        ],
                      });
                    }
                  }}
                  src="/images/down-arrowb.svg"
                />

                <h3>{this.state.products[this.state.productID].title}</h3>

                <Img
                  style={{ width: "30pt", transform: "rotate(270deg)" }}
                  onClick={() => {
                    if (this.state.productID + 1 < this.state.products.length) {
                      this.setState({
                        productID: this.state.productID + 1,
                      });
                      this.setState({
                        chosenItem: [
                          this.state.products.find(
                            (title) =>
                              title.title ===
                              this.state.products[this.state.productID + 1]
                                .title
                          ).options[0].variant[0],
                        ],
                      });
                    }
                  }}
                  src="/images/down-arrowb.svg"
                />
              </div>
              {this.state.products[this.state.productID].options.map(
                (data, index) => {
                  return (
                    <div key={index}>
                      {data.section !== "FeatureDetails" &&
                        data.section !== "2. Select door opening" && (
                          <div
                            style={{ marginTop: "20pt", marginBottom: "40pt" }}
                          >
                            {index !== 0 && (
                              <Img
                                style={{ width: "30pt" }}
                                src="/images/down-arrowb.svg"
                              />
                            )}
                            <h4
                              style={{
                                color: "gray",
                                fontWeight: "350",
                                marginTop: "10pt",
                                marginBottom: "10pt",
                              }}
                            >
                              {data.section}
                            </h4>
                            <h6>{data.subtitle}</h6>
                            {data.variant.map((variantItem, index) => {
                              return (
                                <div
                                  onClick={() => {
                                    // change touched to true so we know now no need to show all tubs in carousel
                                    this.setState({ touched: true });

                                    this.InitateIntervalAllOverAgain();
                                    // type5
                                    // type4
                                    if (
                                      variantItem.type === "type5" ||
                                      variantItem.type === "type4"
                                    ) {
                                      clearInterval(this.CarouselInterval);
                                      this.setState({
                                        imageOrientationIndex: 0,
                                      });
                                    }

                                    // if chosen Item exist is selected already
                                    if (
                                      this.state.chosenItem.findIndex(
                                        (i) => i.name === variantItem.name
                                      ) !== -1
                                    ) {
                                      // then remove the item from the selection by filtering
                                      this.setState({
                                        chosenItem: [
                                          ...this.state.chosenItem.filter(
                                            (i) => i.name !== variantItem.name
                                          ),
                                        ],
                                      });
                                    } else if (variantItem.type === "type1") {
                                      // if its from type1 which is tub itself
                                      //  then disble the selection
                                      this.setState({
                                        imageOrientationIndex: 0,
                                      });
                                      this.disableType("type1");
                                    }

                                    if (
                                      this.state.chosenItem.findIndex(
                                        (i) => i.name === variantItem.name
                                      ) !== -1
                                    ) {
                                      this.setState({
                                        chosenItem: [
                                          ...this.state.chosenItem.filter(
                                            (i) => i.name !== variantItem.name
                                          ),
                                        ],
                                      });
                                    } else if (variantItem.type === "type2") {
                                      this.disableType("type2");
                                    }

                                    if (
                                      this.state.chosenItem.findIndex(
                                        (i) => i.name === variantItem.name
                                      ) === -1
                                    ) {
                                      this.setState({
                                        chosenItem: [
                                          ...this.state.chosenItem,
                                          variantItem,
                                        ],
                                      });
                                    } else {
                                      this.setState({
                                        chosenItem: [
                                          ...this.state.chosenItem.filter(
                                            (i) => i.name !== variantItem.name
                                          ),
                                        ],
                                      });
                                    }
                                  }}
                                  key={index}
                                  className={
                                    this.state.chosenItem.findIndex(
                                      (i) => i.name === variantItem.name
                                    ) === -1
                                      ? "OptionBubble"
                                      : "OptionBubbleActive"
                                  }
                                  style={{
                                    paddingLeft: "20pt",
                                    paddingRight: "20pt",
                                    margin: "20pt",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>{variantItem.name}</div>{" "}
                                  <div style={{ color: "rgb(200,200,200)" }}>
                                    {variantItem.dimensions !== "" ? "- " : ""}
                                  </div>{" "}
                                  <div>
                                    {variantItem.dimensions !== ""
                                      ? variantItem.dimensions
                                      : ""}
                                  </div>{" "}
                                  <div style={{ color: "rgb(230,230,230)" }}>
                                    {variantItem.price !== "" ? " | " : ""}
                                  </div>{" "}
                                  <div>
                                    {variantItem.price !== ""
                                      ? "$ " + variantItem.price
                                      : ""}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      {data.section === "2. Select door opening" && (
                        <div>
                          <h4
                            style={{
                              color: "gray",
                              fontWeight: "350",
                              marginTop: "10pt",
                              marginBottom: "10pt",
                            }}
                          >
                            {data.section}
                          </h4>
                          <h6>{data.subtitle}</h6>
                          <div
                            className="PriceBubble"
                            style={{ marginTop: "30pt" }}
                          >
                            {data.variant.map((variantItem, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    // change touched to true so we know now no need to show all tubs in carousel
                                    this.setState({ touched: true });
                                    clearInterval(this.CarouselInterval);
                                    this.setState({
                                      side: variantItem.name,
                                      imageOrientationIndex: 2,
                                    });
                                  }}
                                  className={
                                    this.state.side === variantItem.name
                                      ? "PriceBubbleItemActive"
                                      : "PriceBubbleItem"
                                  }
                                  style={{
                                    paddingLeft: "20pt",
                                    paddingRight: "20pt",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    {variantItem.name === "lh"
                                      ? "Left Hand"
                                      : "Right Hand"}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                      {/* {
                                        data.section === "FeatureDetails" &&
                                        <div style={{ marginTop: '20pt', marginBottom: '20pt' }}>
                                            <button className="featureButton" onClick={() => {
                                                // Carousel here
                                                this.toggleCarousel()
                                            }}>Feature Details {this.props.match.params.id}</button>
                                        </div>
                                    } */}
                    </div>
                  );
                }
              )}
              {this.CalculateCartPrice() !== 0 && this.TubExists() && (
                <div style={{ paddingTop: "30pt", paddingBottom: "70pt" }}>
                  <h4
                    style={{
                      fontWeight: "lighter",
                      textShadow: "1px 1px 20px white",
                    }}
                  >
                    Ready to order
                  </h4>
                  <div className="">
                    <Link
                      to="/paynow"
                      onClick={() => {
                        this.handleSendDataToPayment();
                      }}
                    >
                      <div
                        className="OrderButton focusedGRN buyButton"
                        style={{
                          backgroundColor: "rgba(16,82,134,0.9)",
                          color: "white",
                          fontSize: "medium",
                          paddingTop: "2.5%",
                          paddingBottom: "2.5%",
                          textDecoration: "none",
                          marginTop: "10pt",
                        }}
                      >
                        Proceed to Pay
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Suspense>
    );
  }
}

export default Byo;
