import React from 'react'
import { useEffect } from 'react';
import './Paynow.css'
import { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastHeader from 'react-bootstrap/ToastHeader'
import Header from './Header';
import axios from 'axios';
import serverConfig from './serverConfig';


export default function Paynow(props) {

    const ValidatePhone = (phone) => {
        // eslint-disable-next-line
        if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(phone)) {
            return (true)
        }
        return (false)
    }

    const ValidateEmail = (mail) => {
        // eslint-disable-next-line
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return (true)
        }
        return (false)
    }

    // localStorage.clear()
    const [ShowToast, setShowToast] = useState(false);
    const [ShowToastBad, setShowToastBad] = useState(false);
    const [ShowToastFormInvalid, setShowToastFormInvalid] = useState(false)

    //billing information
    const [Fname, setFname] = useState("")
    const [Lname, setLname] = useState("")
    const [Username, setUsername] = useState("") //Email
    const [Telephone, setTelephone] = useState("")
    const [State, setState] = useState("")
    const [Zip, setZip] = useState("")
    const [Address, setAddress] = useState("")
    const [City, setCity] = useState("")

    // shipping information
    const [sFname, ssetFname] = useState("")
    const [sLname, ssetLname] = useState("")
    const [sUsername, ssetUsername] = useState("") //Email
    const [sTelephone, ssetTelephone] = useState("")
    const [sState, ssetState] = useState("")
    const [sZip, ssetZip] = useState("")
    const [sAddress, ssetAddress] = useState("")
    const [sCity, ssetCity] = useState("")

    const [CardNumber, setCardNumber] = useState("");
    const [Cvc, setCvc] = useState("");
    const [ExpMonth, setExpMonth] = useState(0);
    const [ExpYear, setExpYear] = useState(0)

    const sortedInvoice = () => {
        let TObesorted = JSON.parse(JSON.stringify(ChosenItems))
        for (let index = 0; index < TObesorted.length; index++) {
            for (let index2 = index + 1; index2 < TObesorted.length; index2++) {
                if (TObesorted[index].price < TObesorted[index2].price) {
                    let temp = JSON.parse(JSON.stringify(TObesorted[index]));
                    TObesorted[index] = JSON.parse(JSON.stringify(TObesorted[index2]));
                    TObesorted[index2] = JSON.parse(JSON.stringify(temp));
                }
            }
        }
        return TObesorted
    }

    // New Implementation for orders
    const [ChosenItems, setChosenItems] = useState([])
    const [side, setside] = useState("lh")
    const calculateSum = () => {
        let sum = 0;
        ChosenItems.forEach(it => {
            sum += it.price * (it.qty !== undefined ? it.qty : 1)
        })
        return sum;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (localStorage.getItem('username') !== undefined)
            axios.post(`${serverConfig.hostUrl}/users/getusers`, { username: localStorage.getItem('username') })
                .then(_ => {
                    setFname(_.data[0].fname)
                    setLname(_.data[0].lname)
                    setUsername(_.data[0].username)
                    setTelephone(_.data[0].telephone)
                    setState(_.data[0].state)
                    setZip(_.data[0].zip)
                    setAddress(_.data[0].address)
                    setCity(_.data[0].city)
                }).catch(_ => {
                    // console.log(_);
                })
        setChosenItems(JSON.parse(localStorage.getItem('CART_ITEMS')))
        setside(localStorage.getItem('DOOR_OPENNING'))
    }, [])

    const DoesExists = (text) => {
        return ChosenItems.findIndex(ci => ci.name === text) !== -1
    }

    const GetSumOfType = (type) => {
        let sum = 0;
        for (let index = 0; index < ChosenItems.length; index++) {
            const element = ChosenItems[index];
            if (element.type === type) {
                sum += element.price
            }
        }
        return sum
    }

    function handlePost(redirectLink) {
        if (localStorage.getItem('ISASSRY') === "YES") {
            const FormData = {
                bfname: Fname,
                blname: Lname,
                bemail: Username,
                baddress: Address,
                bcity: City,
                bzip: Zip,
                bstate: State,
                bcontact: Telephone,
                // Shipping Info
                sfname: sFname,
                slname: sLname,
                semail: sUsername,
                saddress: sAddress,
                scity: sCity,
                szip: sZip,
                sstate: sState,
                scontact: sTelephone,
                // card info
                cardnumber: CardNumber,
                cvc: Cvc,
                expmonth: ExpMonth,
                expyear: ExpYear,
                Accessories: sortedInvoice(),
                total: calculateSum() + 500,
            }
            console.log(sortedInvoice());

            axios.post(`${serverConfig.hostUrl}/order/takeMyOrderB`, FormData, { method: "POST" })
                .then(
                    _ => {
                        if (_.status !== 200) {
                            alert("Could not process transaction")
                        } else {
                            console.log("Success");

                            // alert("Redirected")
                            axios.post(`${serverConfig.hostUrl}/mailer/Accessories`, {
                                from: serverConfig.SystemEmail,
                                to: `${serverConfig.AdminEmail},${Username}`,
                                subject: "Order Confirmed",
                                address: Address,
                                city: City,
                                state: State,
                                zip: Zip,
                                telephone: Telephone,
                                FormData: FormData,
                                ETA: new Date().toLocaleString(),

                            }, { method: "POST" })
                                .then(
                                    _ => {
                                        if (_.status !== 200) {
                                            // alert("Server Offline")
                                        } else {
                                            setShowToast(true)
                                        }
                                    }
                                )
                            window.location.replace(redirectLink)
                        }
                    }
                )
                .catch(_ => {
                    console.log(_);
                    alert("We're under maintenance")
                })
        }
        else {
            const FormData = {
                product: ChosenItems.find(ci => ci.type === "type1").name + side,
                productPrice: ChosenItems.find(ci => ci.type === "type1").price,
                productName: ChosenItems.find(ci => ci.type === "type1").name,
                invoice: {
                    hydroMassage: DoesExists("Hydro Massage (Syllent)") === true ? true : false,
                    airMassage: DoesExists("Air Massage") === true ? true : false,
                    microBubbleSpa: DoesExists("Micro Bubble System") === true ? true : false,
                    bidetWaterjet: DoesExists("Bidet Spa System") === true ? true : false,
                    aromaTherapy: DoesExists("Aroma Therapy") === true ? true : false,
                    chromatherapy: DoesExists("Chroma Therapy") === true ? true : false,
                    heatedSeat: DoesExists("Heated Seat") === true ? true : false,
                    legMassage: DoesExists("Leg Massage") === true ? true : false,
                    ozoneSanitizer: DoesExists("Ozone Sanitizer") === true ? true : false,
                    threeYearPartandLaborWarrenty: DoesExists("3 year parts & labor warranty") === true ? true : false,
                    fiveYearPartandLaborWarrenty: DoesExists("5 year parts & labor warranty") === true ? true : false,
                    tenYearPartandLaborWarrenty: DoesExists("10 year parts & labor warranty") === true ? true : false
                },
                // updated columns
                // Billing Info
                bfname: Fname,
                blname: Lname,
                bemail: Username,
                baddress: Address,
                bcity: City,
                bzip: Zip,
                bstate: State,
                bcontact: Telephone,
                // Shipping Info
                sfname: sFname,
                slname: sLname,
                semail: sUsername,
                saddress: sAddress,
                scity: sCity,
                szip: sZip,
                sstate: sState,
                scontact: sTelephone,
                // card info
                cardnumber: CardNumber,
                cvc: Cvc,
                expmonth: ExpMonth,
                expyear: ExpYear,
                //updated columns uptil here
                systemprice: GetSumOfType("type5"),
                addonsPrice: GetSumOfType("type4"),
                warrentyPrice: GetSumOfType("type2")
            }
            axios.post(`${serverConfig.hostUrl}/order/takeMyOrder`, FormData, { method: "POST" })
                .then(
                    _ => {
                        if (_.status !== 200) {
                            alert("Server Offline")
                        } else {
                            console.log("Success")
                            axios.post(`${serverConfig.hostUrl}/mailer`, {
                                from: serverConfig.SystemEmail,
                                to: `${serverConfig.AdminEmail},${Username}`,
                                subject: "Order Confirmed",
                                address: Address,
                                city: City,
                                state: State,
                                zip: Zip,
                                telephone: Telephone,
                                FormData: FormData,
                                OptionPrice: {
                                    "product": ChosenItems.find(ci => ci.type === "type1").price,
                                    "Hydro": 897,
                                    "Air": 897,
                                    "Micro": 1497,
                                    "Bidet": 197,
                                    "Aroma": 275,
                                    "Chroma": 575,
                                    "Heated": 475,
                                    "Leg": 275,
                                    "Ozone": 475,
                                    "threeyr": 399,
                                    "fiveyr": 499,
                                    "tenyr": 799
                                },
                                ETA: new Date().toLocaleString(),
                                total: calculateSum() + 500

                            }, { method: "POST" })
                                .then(
                                    _ => {
                                        if (_.status !== 200) {
                                            alert("Server Offline")
                                        } else {
                                            setShowToast(true)
                                            window.location.replace(redirectLink)
                                        }
                                    }
                                )
                        }
                    }
                )
                .catch(_ => {
                    console.log(_);
                    alert("We're under maintenance")
                })
        }
    }

    return (
        <div>
            <Header />
            <div style={{ height: '18vh' }}></div>
            {
                // localStorage.getItem('username') &&
                <div>
                    <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-3" >
                            <div className="container">
                                <br />
                                <br />
                                <img alt="" src={localStorage.getItem('TUB_IMAGE')} style={{ width: "100%" }} />
                                <h3
                                    style={{ marginTop: '3vh' }}
                                    onClick={() => {
                                        console.log(ChosenItems);
                                    }}>Your Order Details</h3>
                                <br />
                                {
                                    side === 'rh' &&
                                    <img alt="" src={'/IMAGE/RightOpening.png'} style={{ width: "80%" }} />
                                }
                                {
                                    side === 'lh' &&
                                    <img alt="" src={'/IMAGE/LeftOpening.png'} style={{ width: "80%" }} />
                                }

                                <div style={{
                                    display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center',
                                    justifyContent: 'flex-start', marginBottom: '2vh', marginTop: '2vh'
                                }}>

                                    <div>
                                        {DoesExists("Hydro Massage (Syllent)") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Hydro.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Air Massage") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Air.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Micro Bubble System") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Micro.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Bidet Spa System") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Bidet.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Aroma Therapy") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Aroma.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Chroma Therapy") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Chroma.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Heated Seat") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Heated.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Leg Massage") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Leg.png' />
                                        }
                                    </div>
                                    <div>
                                        {DoesExists("Ozone Sanitizer") &&
                                            <img alt="" style={{ height: "7vh" }} src='images/options/Ozone.png' />
                                        }
                                    </div>
                                    {/* You can add images for your accessories here */}
                                </div>


                                <table
                                    className="table table-table-striped table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <td>
                                                ITEM(s)
                                            </td>
                                            <td>
                                                PRICE
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody style={{ fontSize: "medium" }}>
                                        {
                                            sortedInvoice().map((it, i) => (
                                                <tr key={it.name + it.price}>
                                                    <td>{it.qty}{it.qty === undefined ? "" : "x"} {it.name}</td>
                                                    <td>${it.price}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td>
                                                Flat shipping price:
                                            </td>
                                            <td>
                                                $500
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Total Payable:
                                            </th>
                                            <th>
                                                ${
                                                    calculateSum() + 500
                                                }
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="container">
                                {
                                    false &&
                                    !localStorage.getItem('username') &&
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <div>
                                            <br />
                                            <h3 style={{ textAlign: "center", fontWeight: "lighter" }}>Seems like you are not logged in.</h3>
                                            <h5 style={{ textAlign: "center", fontWeight: "lighter" }}>Enter your name and shipping details so we can process you order</h5>
                                            {/* <Link to='/logintoshop'>click here</Link> if you want to log in instead */}
                                        </div>
                                    </div>
                                }
                                <br />
                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-sm-12">
                                        <h3 style={{ fontWeight: "lighter" }}>Billing Information</h3>
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <input onChange={(event) => { setFname(event.target.value) }} value={Fname} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="First Name" />
                                        <br />
                                    </div>
                                    <div className="col-sm-3">
                                        <input onChange={(event) => { setLname(event.target.value) }} value={Lname} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Last Name" />
                                        <br />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(event) => { setUsername(event.target.value) }} value={Username} style={{ borderRadius: "100px", textAlign: "center", borderColor: !ValidateEmail(Username) && Username !== "" ? 'red' : '' }} className="col-12 focusedBLK form-control" placeholder="Email" />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <input onChange={(event) => { setAddress(event.target.value) }} value={Address} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Address" />
                                        <br />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <input onChange={(event) => { setCity(event.target.value) }} value={City} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="City" />
                                        <br />

                                    </div>
                                    <div className="col-sm-4">
                                        <input type='number' onChange={(event) => { setZip(event.target.value) }} value={Zip} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Zip Code" />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <input onChange={(event) => { setState(event.target.value) }} value={State} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="State" />
                                        <br />
                                    </div>
                                    <div className="col-sm-4">
                                        <input onChange={(event) => { setTelephone(event.target.value) }} value={Telephone} style={{ borderRadius: "100px", textAlign: "center", borderColor: !ValidatePhone(Telephone) && Telephone !== "" ? 'red' : '' }} className="col-12 focusedBLK form-control" placeholder="Contact (XXX)-XXX-XXXX" />
                                        <br />
                                    </div>
                                </div>
                                <br />
                                <h3 style={{ fontWeight: "400", textAlign: 'center' }}>Shipping Information</h3>
                                <br />
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <button onClick={() => {
                                        ssetUsername(Username)
                                        ssetFname(Fname)
                                        ssetLname(Lname)
                                        ssetAddress(Address)
                                        ssetCity(City)
                                        ssetState(State)
                                        ssetZip(Zip)
                                        ssetTelephone(Telephone)
                                    }} className='btn focusedGRN' style={{ backgroundColor: "#3B628E", color: "white", borderRadius: "20pt", paddingRight: "15pt", paddingLeft: "15pt" }}>Shipping Info - Same as above</button>
                                </div>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <input onChange={(event) => { ssetFname(event.target.value) }} value={sFname} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="First Name" />
                                        <br />
                                    </div>
                                    <div className="col-sm-3">
                                        <input onChange={(event) => { ssetLname(event.target.value) }} value={sLname} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Last Name" />
                                        <br />
                                    </div>
                                    <div className="col-sm-6">
                                        <input onChange={(event) => { ssetUsername(event.target.value) }} value={sUsername} style={{ borderRadius: "100px", textAlign: "center", borderColor: !ValidateEmail(sUsername) && sUsername !== "" ? 'red' : '' }} className="col-12 focusedBLK form-control" placeholder="Email" />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <input onChange={(event) => { ssetAddress(event.target.value) }} value={sAddress} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Address" />
                                        <br />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <input onChange={(event) => { ssetCity(event.target.value) }} value={sCity} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="City" />
                                        <br />

                                    </div>
                                    <div className="col-sm-4">
                                        <input type='number' onChange={(event) => { ssetZip(event.target.value) }} value={sZip} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Zip Code" />
                                        <br />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <input onChange={(event) => { ssetState(event.target.value) }} value={sState} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="State" />
                                        <br />
                                    </div>
                                    <div className="col-sm-4">
                                        <input onChange={(event) => { ssetTelephone(event.target.value) }} value={sTelephone} style={{ borderRadius: "100px", textAlign: "center", borderColor: !ValidatePhone(sTelephone) && sTelephone !== "" ? 'red' : '' }} className="col-12 focusedBLK form-control" placeholder="Contact (XXX)-XXX-XXXX" />
                                        <br />
                                    </div>
                                </div>


                                <div className="row" style={{ textAlign: "center" }}>
                                    <div className="col-sm-12" style={{ fontSize: 'x-large' }}>
                                        Card Information
                                    </div>
                                </div>
                                <br />
                                <br />

                                <div className="row">
                                    <div className="col-sm-12">
                                        <input onChange={(event) => { setCardNumber(event.target.value) }} value={CardNumber} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Card Number" />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-6">
                                        <input onChange={(event) => { setCvc(event.target.value) }} value={Cvc} style={{ borderRadius: "100px", textAlign: "center" }} className="col-12 focusedBLK form-control" placeholder="Cvc" />
                                        <br />

                                    </div>
                                    <div className="col-sm-3">
                                        <input onChange={(event) => {
                                            setExpMonth(event.target.value)
                                            if (Number.parseInt(ExpMonth) > 12 && Number.parseInt(ExpMonth) < 1) {
                                                setExpMonth(1)
                                            }
                                        }} value={ExpMonth} style={{ borderRadius: "100px", textAlign: "center" }} type='number' min={1} max={12} className="col-12 focusedBLK form-control" placeholder="Exp Month (1-12)" />
                                        <br />
                                    </div>
                                    <div className="col-sm-3">
                                        <input onChange={(event) => { setExpYear(event.target.value) }} value={ExpYear} style={{ borderRadius: "100px", textAlign: "center" }} type='number' min={2022} max={2050} className="col-12 focusedBLK form-control" placeholder="Exp Year" />
                                        <br />
                                    </div>
                                </div>












                                <br />
                                <div className='row'>
                                    <div className='col-sm-4'></div>
                                    <div className="col-sm-4">
                                        <button onClick={() => {
                                            if (
                                                Fname === "" ||
                                                Lname === "" ||
                                                Username === "" ||
                                                Telephone === "" ||
                                                State === "" ||
                                                Zip === "" ||
                                                Address === "" ||
                                                City === "" ||
                                                sFname === "" ||
                                                sLname === "" ||
                                                sUsername === "" ||
                                                sTelephone === "" ||
                                                sState === "" ||
                                                sZip === "" ||
                                                sAddress === "" ||
                                                sCity === "" ||
                                                CardNumber === "" ||
                                                Cvc === "" ||
                                                ExpMonth === "" ||
                                                ExpYear === ""

                                            ) {
                                                setShowToastFormInvalid(true)
                                                return;
                                            }

                                            axios.post(`${serverConfig.hostUrl}/payment/authorize/${calculateSum() + 500}`, {
                                                CardNumber: CardNumber,
                                                Cvc: Cvc,
                                                ExpMonth: ExpMonth,
                                                ExpYear: ExpYear,
                                                Fname: Fname,
                                                Lname: Lname,
                                                Username: Username,
                                                Telephone: Telephone,
                                                State: State,
                                                Zip: Zip,
                                                Address: Address,
                                                City: City,
                                                sFname: sFname,
                                                sLname: sLname,
                                                sUsername: sUsername,
                                                sTelephone: sTelephone,
                                                sState: sState,
                                                sZip: sZip,
                                                sAddress: sAddress,
                                                sCity: sCity,
                                                Amount: calculateSum() + 500
                                            })
                                                .then(_ => {
                                                    console.log(_.data)
                                                    if (_.data.success === "OK") {
                                                        setShowToast(true)
                                                        handlePost();
                                                    }
                                                    else {
                                                        console.log(_.data.message);
                                                        setShowToastBad(true)
                                                    }
                                                })
                                                .catch(_ => {
                                                    console.log(_.data);
                                                    alert("An error occured try again later")
                                                })
                                        }} style={{ borderRadius: "100px", backgroundColor: "rgba(16,82,134,0.9)", color: "white", }} className="col-12 focusedGRN btn">PAY WITH CARD</button>
                                    </div>
                                    <div className='col-sm-4'></div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col-sm-4"></div>
                                    <div className="col-sm-4" style={{ textAlign: 'center' }}>
                                        OR
                                    </div>
                                    <div className="col-sm-4"></div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-4"></div>
                                    <div className="col-sm-4">
                                        <button onClick={() => {
                                            if (
                                                Fname === "" ||
                                                Lname === "" ||
                                                Username === "" ||
                                                Telephone === "" ||
                                                State === "" ||
                                                Zip === "" ||
                                                Address === "" ||
                                                City === "" ||
                                                sFname === "" ||
                                                sLname === "" ||
                                                sUsername === "" ||
                                                sTelephone === "" ||
                                                sState === "" ||
                                                sZip === "" ||
                                                sAddress === "" ||
                                                sCity === ""
                                                // CardNumber === "" ||
                                                // Cvc === "" ||
                                                // ExpMonth === "" ||
                                                // ExpYear === ""

                                            ) {
                                                setShowToastFormInvalid(true)
                                                return;
                                            }

                                            axios.get(`${serverConfig.hostUrl}/payment/make/${calculateSum() + 500}`)
                                                .then(_ => {
                                                    console.log(_)
                                                    handlePost(_.data);
                                                })
                                                .catch(_ => {
                                                    alert("An error occured try again later")
                                                })
                                        }} style={{ borderRadius: "100px", backgroundColor: "rgba(16,82,134,0.9)", color: "white" }} className="col-12 focusedGRN btn">PAY WITH PAYPAL</button>
                                    </div>
                                    <div className="col-sm-4">
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-sm-4">
                                    </div>
                                    <div className="col-sm-4">
                                        <button
                                            onClick={() => {
                                                localStorage.removeItem('TUB_IMAGE');
                                                localStorage.removeItem('CART_ITEMS');
                                                localStorage.removeItem('DOOR_OPENNING');
                                                localStorage.removeItem('ISASSRY');
                                                props.history.goBack()
                                            }}
                                            className="btn btn-dark col-12" style={{ borderRadius: "20pt", }}>Cancel Order
                                        </button>
                                    </div>
                                    <div className="col-sm-4">
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>

                        <div className="col-md-1"></div>
                    </div>

                    <div style={{ position: "fixed", zIndex: '1000', bottom: "30pt", left: "20pt" }}>
                        <Toast bg="success" onClose={() => setShowToast(false)} show={ShowToast} delay={3000} autohide>
                            <ToastHeader>
                                <strong className="me-auto">Success</strong>
                            </ToastHeader>
                            <Toast.Body>Success</Toast.Body>
                        </Toast>
                        <Toast bg="danger" onClose={() => setShowToastBad(false)} show={ShowToastBad} delay={3000} autohide>
                            <Toast.Header>
                                <strong className="me-auto" style={{ color: 'red' }}>Failure</strong>
                            </Toast.Header>
                            <Toast.Body>Your payment couldn't be processed</Toast.Body>
                        </Toast>
                        <Toast bg="danger" onClose={() => setShowToastFormInvalid(false)} show={ShowToastFormInvalid} delay={3000} autohide>
                            <Toast.Header>
                                <strong className="me-auto" style={{ color: 'red' }}>Invalid form</strong>
                            </Toast.Header>
                            <Toast.Body>Check if form is filled properly</Toast.Body>
                        </Toast>
                    </div>
                </div>
            }
        </div>
    )
}