/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
// import styled from 'styled-components';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom';
import serverConfig from './serverConfig';
import { useState } from 'react';
import './Accounts.css'
import axios from 'axios';
import { useEffect } from 'react';
import State from '../app/State';
import Footer from './Footer';
import React from 'react';
import Header from '../components/Header';

function Account(props) {
    var imported = false;
    const [username, setusername] = useState("")
    const [password, setpassword] = useState("")
    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [telephone, settelephone] = useState("");
    const [address, setaddress] = useState("");
    const [city, setcity] = useState("");
    const [state, setstate] = useState("");
    const [zip, setzip] = useState("");
    // var passwordFieldText = "";
    // if signedIn then fetch more data on basis of user

    useEffect(() => {

        window.scrollTo(0, 0)

        if (localStorage.getItem('username'))
            axios.post(`${serverConfig.hostUrl}/users/getusers`, { username: localStorage.getItem('username') }, { method: "POST" }).then(_ => {
                var user = _.data[0]
                console.log(user)
                setaddress(user.address)
                setcity(user.city)
                setfname(user.fname)
                setlname(user.lname)
                setpassword(user.password)
                setstate(user.state)
                settelephone(user.telephone)
                setusername(user.username)
                setzip(user.zip)
                imported = true
            })
        return
    }, [])

    if (localStorage.getItem('username')) {


        return (
            <>
                <Header />
                <div style={{height:'17vh'}}></div>
                {/* // update account */}
                <form

                    onSubmit={(event) => {
                        // console.log(username);
                        console.log(password);
                        console.log(fname);
                        console.log(lname);
                        console.log(telephone);
                        console.log(address);

                        event.preventDefault();
                        var valid = true;
                        // if (username == "") {
                        //     document.getElementById('usernameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                        //     valid = false;
                        // }
                        if (password === "") {
                            document.getElementById('passwordField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (fname === "") {
                            document.getElementById('fnameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (lname === "") {
                            document.getElementById('lnameField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (telephone === "") {
                            document.getElementById('telephoneField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (address === "") {
                            document.getElementById('addressField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (city === "") {
                            document.getElementById('cityField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (state === "") {
                            document.getElementById('stateField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (zip === "") {
                            document.getElementById('zipField').setAttribute('style', "border: 1px solid red;border-radius:20px;text-align:'center'")
                            valid = false;
                        }
                        if (valid) {
                            alert("Updated...")
                            // login logic
                            axios.post(`${serverConfig.hostUrl}/users/updateuser`, {
                                username: username,
                                password: password,
                                fname: fname,
                                lname: lname,
                                telephone: telephone,
                                address: address,
                                city: city,
                                state: state,
                                zip: zip
                            }, { method: "POST" }).then(_ => {
                                console.log("DOne")
                            }).catch(_ => {
                                console.log("masla")
                            })

                        } else {
                            alert("Form Invalid")
                        }

                    }}
                    className="container"
                    style={{
                        marginTop: "5%",
                        width: "340px",
                    }}>
                    <h1 style={{
                        fontWeight: "lighter"
                    }}>WELCOME <div style={{ textTransform: "uppercase" }}>{localStorage.getItem('username')}</div></h1>
                    <hr />

                    {/* <div className="row">
                    <div className="col-sm-12">
                        <label for="usernameField" style={{
                            marginLeft: "15px"
                        }}>username</label>
                        <input id="usernameField" className="form-control focusedBLK" style={{
                            borderRadius: "20px",
                            textAlign: "center"
                        }}
                            onChange={(word) => {
                                setusername(word.target.value)
                                document.getElementById('usernameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                            }}
                        />
                    </div>
                </div>
                <br /> */}

                    <div className="row">
                        <div className="col-sm-12">
                            <label for="passwordField" style={{
                                marginLeft: "15px"
                            }}>Password</label>
                            <input value={password} type="password" id="passwordField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setpassword(word.target.value)
                                    document.getElementById('passwordField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                            <div style={{ textAlign: "end", marginRight: "15px" }}>
                                <i className="btn" id="hideShow"
                                    onMouseEnter={
                                        _ => {
                                            document.getElementById('passwordField').setAttribute('type', 'text')
                                            document.getElementById('hideShow').innerText = "Hide Password"
                                        }
                                    }
                                    onMouseLeave={
                                        _ => {
                                            document.getElementById('passwordField').setAttribute('type', 'password')
                                            document.getElementById('hideShow').innerText = "Show Password"
                                        }
                                    }
                                >Show Password</i></div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <label for="fnameField" style={{
                                marginLeft: "15px"
                            }}>First Name</label>
                            <input value={fname} id="fnameField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setfname(word.target.value)
                                    document.getElementById('fnameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <label for="lnameField" style={{
                                marginLeft: "15px"
                            }}>Last Name</label>
                            <input value={lname} id="lnameField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setlname(word.target.value)
                                    document.getElementById('lnameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-sm-12">
                            <label for="telephoneField" style={{
                                marginLeft: "15px"
                            }}>Telephone</label>
                            <input value={telephone} id="telephoneField" type="telephone" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}

                                onChange={(word) => {
                                    settelephone(word.target.value)
                                    document.getElementById('telephoneField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }} />
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        <div className="col-sm-12">
                            <label for="addressField" style={{
                                marginLeft: "15px"
                            }}>Address</label>
                            <input value={address} id="addressField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setaddress(word.target.value)
                                    document.getElementById('addressField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <label for="cityField" style={{
                                marginLeft: "15px"
                            }}>City</label>
                            <input value={city} id="cityField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setcity(word.target.value)
                                    document.getElementById('cityField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <label for="stateField" style={{
                                marginLeft: "15px"
                            }}>State</label>
                            <input value={state} id="stateField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setstate(word.target.value)
                                    document.getElementById('stateField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-sm-12">
                            <label for="zipField" style={{
                                marginLeft: "15px"
                            }}>Zip</label>
                            <input value={zip} id="zipField" className="form-control focusedBLK" style={{
                                borderRadius: "20px",
                                textAlign: "center"
                            }}
                                onChange={(word) => {
                                    setzip(word.target.value)
                                    document.getElementById('zipField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;text-align:'center'")
                                }}
                            />
                        </div>
                    </div>
                    <br />

                    <br />
                    <button type="submit" className="btn btn-success col-sm-12 focusedGRN" style={{
                        borderRadius: "20px"
                    }}>UPDATE DETAILS</button>
                    <br />
                    <br />
                    <button to='/' className="btn btn-danger col-sm-12 focusedRED" style={{
                        borderRadius: "20px"
                    }}
                        onClick={() => {
                            localStorage.removeItem('username')
                            props.history.push('/account')
                        }}
                    >SIGN OUT

                    </button>
                    <br />
                    <br />
                    <Link to='/createAccount' className="btn btn-outline-primary col-sm-12 focusedBLK" style={{
                        borderRadius: "20px"
                    }}>CREATE NEW</Link>
                    <br />
                    <br />
                    <Link to='/' className="btn btn-outline-dark col-sm-12 focusedBLK" style={{
                        borderRadius: "20px"
                    }}>CANCEL</Link>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div style={{ textJustify: "center", textAlign: "center", color: "grey" }}>
                        <hr />
                        Energytubs ©️ All rights reserved
                    </div>
                    <br />
                </form>
            </>
        );
    } else {
        // sign in
        return (
            <>
                <Header />
                <div style={{height:'17vh'}}></div>
                <div className="container" style={{
                    marginTop: '5%',
                    width: "340px",
                }}>
                    <div className="row">
                        <div>
                            <h1
                                style={{
                                    fontWeight: "lighter",
                                    marginLeft: "15px"
                                }}
                            >Sign In</h1>
                            <hr />
                            <form onSubmit={(event) => {
                                event.preventDefault();
                                var valid1 = false;
                                var valid2 = false;
                                var user = {
                                    username: "",
                                    password: ""
                                }
                                axios.post(`${serverConfig.hostUrl}/users/getusers`, { username: username }, { method: "POST" }).then(_ => {
                                    user = _.data[0]
                                    console.log(user)
                                    if (user !== undefined) {

                                        if (username === "" || username === undefined || user.username !== username) {
                                            console.log("Bad pass")
                                            valid1 = false;
                                            document.getElementById('usernameField').setAttribute('style', "border: 1px solid red;border-radius:20px;textAlign:'center'")
                                        } else {
                                            valid1 = true;
                                            console.log("Good pass")
                                            document.getElementById('usernameField').setAttribute('style', "border: 1px solid grey;border-radius:20px;textAlign:'center'")
                                        }
                                        if (password === "" || password === undefined || user.password !== password) {
                                            console.log("Bad passwd")
                                            valid2 = false;
                                            document.getElementById('passwordField').setAttribute('style', "border: 1px solid red;border-radius:20px;textAlign:'center'")

                                        } else {
                                            valid2 = true;
                                            console.log("Good passwd")
                                            document.getElementById('passwordField').setAttribute('style', "border: 1px solid grey;border-radius:20px;textAlign:'center'")
                                        }
                                    } else {
                                        alert("User doesn't exist")
                                    }

                                    if (valid1 && valid2) {
                                        // sign in login
                                        console.log("LoggedIn")
                                        localStorage.setItem('username', username)
                                        State.username = username;
                                        return props.history.push('/')
                                    }
                                })

                            }}>
                                <label for='usernameField' style={{
                                    marginLeft: "15px"
                                }}
                                >Enter Email</label>
                                <input id='usernameField' className="form-control hoverAbleButton" style={{
                                    borderRadius: "20px",
                                    textAlign: "center"
                                }}
                                    value={username}
                                    onChange={(word) => {
                                        setusername(word.target.value);
                                        document.getElementById('usernameField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;textAlign:'center'")
                                    }}
                                />
                                <br />
                                <label for='passwordField' style={{
                                    marginLeft: "15px"
                                }}
                                >Password</label>
                                <input id='passwordField' className="form-control hoverAbleButton"
                                    type="password"
                                    style={{
                                        borderRadius: "20px",
                                        textAlign: "center"
                                    }}
                                    value={password}
                                    onChange={(word) => {
                                        setpassword(word.target.value);
                                        document.getElementById('passwordField').setAttribute('style', "border: 1px solid lightgray;border-radius:20px;textAlign:'center'")
                                    }}
                                />
                                <div style={{ textAlign: "end", marginRight: "15px" }}>
                                    <i className='btn' id='hideShow' onMouseEnter={
                                        _ => {
                                            document.getElementById('hideShow').innerText = 'Hide Password'
                                            document.getElementById('passwordField').setAttribute('type', 'text')
                                        }}
                                        onMouseLeave={
                                            _ => {
                                                document.getElementById('hideShow').innerText = 'Show Password'
                                                document.getElementById('passwordField').setAttribute('type', 'password')
                                            }}>Show Password</i>
                                </div>
                                <br />

                                <button type="submit" className="btn btn-primary col-sm-12 hoverAbleButtonGRN" style={{
                                    borderRadius: "20px",
                                    marginBottom: "10px",
                                    borderWidth: "2px"
                                }}
                                >SIGN IN</button>
                                <br />



                                <Link to='/' className="btn btn-light col-sm-12 hoverAbleButtonCNO" style={{
                                    borderRadius: "20px",
                                    backgroundColor: "lightGray",
                                }}
                                >CANCEL</Link>
                                <br /><br />
                                {/* <div style={{ textAlign: "right", marginRight: "25px" }}>
                                Have you <Link>forgot</Link> your password</div> */}
                                <br />
                                <div className='row' style={{
                                    textAlign: "center",
                                    marginBottom: "20px"
                                }}>
                                    <div className='col-sm-5'><hr /></div>
                                    <div className='col-sm-2'>OR</div>
                                    <div className='col-sm-5'><hr /></div>
                                </div>

                                <Link to='/createAccount' className="btn btn-outline-success col-sm-12 hoverAbleButtonCNOGRN" style={{
                                    borderRadius: "20px"
                                }}>
                                    Create Account
                                </Link>

                            </form>
                        </div>
                    </div>
                    <div style={{ textAlign: "right", padding: "10pt" }}>
                        <Link to={'/'} style={{ textDecoration: "none" }}>Go Back</Link>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                <Footer />
            </>

        );
    }

}
export default Account;
