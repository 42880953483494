import axios from 'axios';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import serverConfig from './serverConfig';
import { CSVLink } from 'react-csv';

export default class Admin extends Component {
    checkUncheck = (emailToAdd) => {
        let found = false;
        for (let i = 0; i < this.state.emailRecipients.length; i++) {
            const element = this.state.emailRecipients[i];
            if (element === emailToAdd) {
                found = true;
            }
        }
        if (!found) {
            this.state.emailRecipients.push(emailToAdd)
        } else {
            let filtered = this.state.emailRecipients.filter(e => { return e !== emailToAdd })
            this.state.emailRecipients.splice(0, this.state.emailRecipients.length);
            this.state.emailRecipients.push(...filtered);
        }
        console.log(this.state.emailRecipients);
    }
    constructor() {
        super();
        this.state = {
            emailRecipients: [],
            orders: [],
            messages: [],
            newsletter: [],
            users: [],
            activeDisplay: "ORDERS",
            message: "",
            accessoriesOrders: [],
            accessoriesOrdersINVIDs: [],
            paginationIndex: 0,
            recordsPerPage: 5,
            loggedIn: false,
            username: "",
            password: "",
        }


        axios.get(`${serverConfig.hostUrl}/x/getOrders`, { responseType: "json" })
            .then(_ => {
                this.setState({
                    orders: _.data
                })
            })
            .catch(_ => {
                console.log(_);
            });

        axios.get(`${serverConfig.hostUrl}/x/getMessages`, { responseType: "json" })
            .then(_ => {
                this.setState({
                    messages: _.data
                })
            })
            .catch(_ => {
                console.log(_);
            });

        axios.get(`${serverConfig.hostUrl}/x/getUsers`, { responseType: "json" })
            .then(_ => {
                this.setState({
                    users: _.data
                })
            })
            .catch(_ => {
                console.log(_);
            });

        axios.get(`${serverConfig.hostUrl}/x/getNewsletter`, { responseType: "json" })
            .then(_ => {
                this.setState({
                    newsletter: _.data
                })
            })
            .catch(_ => {
                console.log(_);
            });

        axios.get(`${serverConfig.hostUrl}/x/getAccessoryOrders`, { responseType: "json" })
            .then(_ => {

                this.setState({
                    accessoriesOrders: _.data
                }, () => {
                    // console.log(this.state.accessoriesOrders);
                })

            })
            .catch(_ => {
                console.log(_);
            });
        axios.get(`${serverConfig.hostUrl}/x/getAccessoryOrdersINV`, { responseType: "json" })
            .then(_ => {

                this.setState({
                    accessoriesOrdersINVIDs: _.data
                }, () => {
                    // console.log(this.state.accessoriesOrdersINVIDs);
                })

            })
            .catch(_ => {
                console.log(_);
            });

    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    hndlgn = () => {
        axios.post(`${serverConfig.hostUrl}/users/adminCred`, {
            u: this.state.username,
            p: this.state.password
        })
            .then(res => {
                if (res.data.length === 1) {
                    this.setState({
                        loggedIn: true
                    })
                } else {
                    alert("Invalid Username or Password")
                }
                this.setState({
                    username: "",
                    password: "",
                })
            })
            .catch(err => {
                alert("Server Offline")
            });
    }
    hndlgt = () => {
        this.setState({
            loggedIn: false
        })
    }

    hndlPssCng = () => {
        if (this.state.username !== "" && this.state.password !== "") {
            axios.post(`${serverConfig}/users/chngAdminCred`, {
                u: this.state.username,
                p: this.state.password
            })
                .then(res => {
                    if (window.confirm("Are you sure?") === true) {
                        this.setState({
                            loggedIn: false
                        })
                    }
                })
                .catch(err => {
                    alert("Error setting new Password")
                })
        }
    }

    render() {
        return this.state.loggedIn === true ? (
            <>
                <div style={{ overflow: "scroll", marginTop: "15vh" }} className='container'>
                    <h1 style={{ textAlign: "center" }}>Energytubs Dashboard</h1>
                    <br />
                    <button onClick={() => {
                        axios.post(`${serverConfig.hostUrl}/mailer/message`, {
                            from: serverConfig.SystemEmail,
                            to: serverConfig.AdminEmail,
                            subject: "Mail server working properly ✌️",
                            text: "Test mail successfully received"
                        })
                            .then(_ => {
                                console.log(_);
                                alert("Test complete")
                            })
                            .catch(_ => {
                                console.log(_);
                                alert("Test Failed")
                            })
                    }} className="btn btn-primary">Test Connectivity</button>{" | "}
                    <button onClick={() => {
                        this.setState({
                            loggedIn:false
                        })
                    }} className="btn btn-danger">Log out</button>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <div className='btn btn-group' style={{ marginBottom: "5vh", textAlign: "center" }}>
                            <button style={{ backgroundColor: this.state.activeDisplay === "USERS" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "USERS", paginationIndex: 0 })
                            }}>Users</button>
                            <button style={{ backgroundColor: this.state.activeDisplay === "ORDERS" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "ORDERS", paginationIndex: 0 })
                            }}>Orders</button>
                            <button style={{ backgroundColor: this.state.activeDisplay === "NEWSLETTER" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "NEWSLETTER", paginationIndex: 0 })
                            }}>Newsletter Subscribers</button>
                            <button style={{ backgroundColor: this.state.activeDisplay === "QUERIES" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "QUERIES", paginationIndex: 0 })
                            }}>Queries</button>
                            <button style={{ backgroundColor: this.state.activeDisplay === "PE" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "PE", paginationIndex: 0 })
                            }}>Promotional Emails</button>
                            <button style={{ backgroundColor: this.state.activeDisplay === "ACCESSORIES" ? "#575e65" : "" }} className='btn btn-secondary' onClick={() => {
                                this.setState({ activeDisplay: "ACCESSORIES", paginationIndex: 0 })
                            }}>Accessory Orders</button>
                        </div>
                    </div>

                    <div>
                        <table style={{ width: 'fit-content' }} className='table table-bordered table-primary table-striped'>
                            <tbody>
                                <tr>
                                    <th>
                                        Subscribers
                                    </th>
                                    <td>
                                        {this.state.newsletter === null ? "Not Loaded" : this.state.newsletter.length}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Users
                                    </th>
                                    <td>
                                        {this.state.users === null ? "Not Loaded" : this.state.users.length}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Orders
                                    </th>
                                    <td>
                                        {this.state.orders === null ? "Not Loaded" : this.state.orders.length}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {
                        this.state.activeDisplay === "QUERIES" &&
                        <div>
                            <h2>Queries</h2>
                            <hr />
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Name</th>
                                        <th>Query</th>
                                        <th>Telephone</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.messages !== null &&
                                        this.state.messages.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(data => {
                                            return <tr>
                                                <td>{data.email}</td>
                                                <td>{data.name}</td>
                                                <td>{data.message}</td>
                                                <td>{data.phonenumber}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                            <div className="btn-group">
                                <button disabled={
                                    this.state.paginationIndex === 0
                                }
                                    className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                        })
                                    }}>Previous</button>
                                <button disabled={
                                    this.state.messages.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                } className='btn btn-outline-dark' onClick={() => {
                                    this.setState({
                                        paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                    })
                                }}>Next</button>
                            </div>
                            <br />
                            <br />
                            <hr />
                        </div>
                    }
                    {
                        this.state.activeDisplay === "NEWSLETTER" &&
                        <div>
                            <h2>News Letter Subscribers</h2>
                            <hr />
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.newsletter !== null &&
                                        this.state.newsletter.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(data => {
                                            return <tr>
                                                <td>{data.email}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                            <div className="btn-group">
                                <button disabled={
                                    this.state.paginationIndex === 0
                                }
                                    className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                        })
                                    }}>Previous</button>
                                <button disabled={
                                    this.state.newsletter.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                } className='btn btn-outline-dark' onClick={() => {
                                    this.setState({
                                        paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                    })
                                }}>Next</button>
                            </div>
                            <br />
                            <br />

                            <hr />
                        </div>
                    }
                    {
                        this.state.activeDisplay === "ACCESSORIES" &&
                        <div>
                            <h2>Accessory Orders</h2>
                            <hr />
                            <table className="table table-bordered table-striped">
                                {
                                    this.state.accessoriesOrdersINVIDs.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(recordParent => (
                                        <tbody>
                                            <tr style={{ padding: '2%' }}>
                                                <td>
                                                    <b>Invoice ID: </b>{recordParent.INVOICE_ID}
                                                </td>
                                                <td>
                                                    <b>Name: </b>{recordParent.bfname} {recordParent.blname}
                                                </td>
                                                <td>
                                                    <b>Email: </b>{recordParent.bemail}
                                                </td>
                                                <td>
                                                    <b>Sum Total: </b>{recordParent.SUM_TOTAL}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <table className='table table-bordered table-striped'>
                                                        <thead>
                                                            <tr>
                                                                <th>Accessory Name</th>
                                                                <th>Qunatity</th>
                                                                <th>Unit Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.accessoriesOrders !== null &&
                                                                this.state.accessoriesOrders.filter(ao => ao.assryInvId === recordParent.INVOICE_ID).map(data => {
                                                                    return <tr>
                                                                        <td>{data.AccessoryName}</td>
                                                                        <td>{data.Quantity}</td>
                                                                        <td>{data.unitPrice}</td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }

                            </table>
                            <br />
                            <div className="btn-group">
                                <button disabled={
                                    this.state.paginationIndex === 0
                                }
                                    className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                        })
                                    }}>Previous</button>
                                <button disabled={
                                    this.state.accessoriesOrdersINVIDs.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                } className='btn btn-outline-dark' onClick={() => {
                                    this.setState({
                                        paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                    })
                                }}>Next</button>
                            </div>
                            <br />
                            <br />
                            <hr />
                        </div>
                    }
                    {
                        this.state.activeDisplay === "USERS" &&
                        <div>
                            <h2>Registered Users</h2>
                            <hr />
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>First name</th>
                                        <th>Last name</th>
                                        <th>Telephone</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>Zip</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users !== null &&
                                        this.state.users.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(data => {
                                            return <tr>
                                                <td>{data.username}</td>
                                                <td>{data.fname}</td>
                                                <td>{data.lname}</td>
                                                <td>{data.telephone}</td>
                                                <td>{data.address}</td>
                                                <td>{data.city}</td>
                                                <td>{data.state}</td>
                                                <td>{data.zip}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            <br />
                            <div className="btn-group">
                                <button disabled={
                                    this.state.paginationIndex === 0
                                }
                                    className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                        })
                                    }}>Previous</button>
                                <button disabled={
                                    this.state.users.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                } className='btn btn-outline-dark' onClick={() => {
                                    this.setState({
                                        paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                    })
                                }}>Next</button>
                            </div>
                            <br />
                            <hr />
                        </div>
                    }
                    {
                        this.state.activeDisplay === "ORDERS" &&
                        <div>
                            <h2>Previous Orders</h2>
                            <hr />
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Product Name</th>
                                        <th>Produce Price</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Invoice</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.orders !== null &&
                                        this.state.orders.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(data => {
                                            return <tr>
                                                <td>{data.order_id}</td>
                                                <td>{data.productName}</td>
                                                <td>{data.productPrice}</td>
                                                <td>{data.bfname} {data.blname}</td>
                                                <td>{data.bemail}</td>
                                                <td>
                                                    <CSVLink data={[
                                                        [
                                                            `${data.order_id}`.toString(),
                                                            `${data.productName}`.toString(),
                                                            `${data.productPrice}`.toString(),
                                                            `${data.hydroMassage}`.toString(),
                                                            `${data.airMassage}`.toString(),
                                                            `${data.microBubbleSpa}`.toString(),
                                                            `${data.bidetWaterjet}`.toString(),
                                                            `${data.aromaTherapy}`.toString(),
                                                            `${data.chromatherapy}`.toString(),
                                                            `${data.heatedSeat}`.toString(),
                                                            `${data.legMassage}`.toString(),
                                                            `${data.ozoneSanitizer}`.toString(),
                                                            `${data.threeYearPartandLaborWarrenty}`.toString(),
                                                            `${data.fiveYearPartandLaborWarrenty}`.toString(),
                                                            `${data.tenYearPartandLaborWarrenty}`.toString(),
                                                            `${data.systemprice}`.toString(),
                                                            `${data.addonsPrice}`.toString(),
                                                            `${data.warrentyPrice}`.toString(),
                                                            `${data.bfname}`.toString(),
                                                            `${data.blname}`.toString(),
                                                            `${data.bemail}`.toString(),
                                                            `${data.baddress}`.toString(),
                                                            `${data.bcity}`.toString(),
                                                            `${data.bzip}`.toString(),
                                                            `${data.bstate}`.toString(),
                                                            `${data.bcontact}`.toString(),
                                                            `${data.sfname}`.toString(),
                                                            `${data.slname}`.toString(),
                                                            `${data.semail}`.toString(),
                                                            `${data.saddress}`.toString(),
                                                            `${data.scity}`.toString(),
                                                            `${data.szip}`.toString(),
                                                            `${data.sstate}`.toString(),
                                                            `${data.scontact}`.toString(),
                                                            `${data.cardnumber}`.toString(),
                                                            `${data.cvc}`.toString(),
                                                            `${data.expmonth}`.toString(),
                                                            `${data.expyear}.toString()`
                                                        ]
                                                    ]} headers={[
                                                        "Invoice ID",
                                                        "Product Name",
                                                        "Product Price",
                                                        "HydroMassage",
                                                        "AirMassage",
                                                        "MicroBubbleSpa",
                                                        "BidetWaterjet",
                                                        "AromaTherapy",
                                                        "Chromatherapy",
                                                        "HeatedSeat",
                                                        "LegMassage",
                                                        "OzoneSanitizer",
                                                        "ThreeYearPartandLaborWarrenty",
                                                        "FiveYearPartandLaborWarrenty",
                                                        "TenYearPartandLaborWarrenty",
                                                        "Systemprice",
                                                        "AddonsPrice",
                                                        "WarrentyPrice",
                                                        "Billing First Name",
                                                        "Billing Last Name",
                                                        "Billing Email",
                                                        "Billing Address",
                                                        "Billing City",
                                                        "Billing Zip",
                                                        "Billing State",
                                                        "Billing Contact",
                                                        "Shipping First Name",
                                                        "Shipping Last Name",
                                                        "Shipping Email",
                                                        "Shipping Address",
                                                        "Shipping City",
                                                        "Shipping Zip",
                                                        "Shipping State",
                                                        "Shipping Contact",
                                                        "Card Number",
                                                        "Cvc",
                                                        "Expiry Month",
                                                        "Expiry Year",
                                                    ]} >
                                                        Download All
                                                    </CSVLink>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            <br />
                            <div className="btn-group">
                                <button disabled={
                                    this.state.paginationIndex === 0
                                }
                                    className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                        })
                                    }}>Previous</button>
                                <button disabled={
                                    this.state.orders.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                } className='btn btn-outline-dark' onClick={() => {
                                    this.setState({
                                        paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                    })
                                }}>Next</button>
                            </div>
                            <br />
                            <br />
                            <CSVLink data={this.state.orders} headers={[
                                "Invoice ID",
                                "Product",
                                "Product Price",
                                "Product Name",
                                "HydroMassage",
                                "AirMassage",
                                "MicroBubbleSpa",
                                "BidetWaterjet",
                                "AromaTherapy",
                                "Chromatherapy",
                                "HeatedSeat",
                                "LegMassage",
                                "OzoneSanitizer",
                                "ThreeYearPartandLaborWarrenty",
                                "FiveYearPartandLaborWarrenty",
                                "TenYearPartandLaborWarrenty",
                                "Systemprice",
                                "AddonsPrice",
                                "WarrentyPrice",
                                "Billing First Name",
                                "Billing Last Name",
                                "Billing Email",
                                "Billing Address",
                                "Billing City",
                                "Billing Zip",
                                "Billing State",
                                "Billing Contact",
                                "Shipping First Name",
                                "Shipping Last Name",
                                "Shipping Email",
                                "Shipping Address",
                                "Shipping City",
                                "Shipping Zip",
                                "Shipping State",
                                "Shipping Contact",
                                "Card Number",
                                "Cvc",
                                "Expiry Month",
                                "Expiry Year",
                            ]} >
                                Download All
                            </CSVLink>
                            <hr />
                        </div>
                    }
                    {
                        this.state.activeDisplay === "PE" &&
                        <div>
                            <h2>Send Promotional Email</h2>
                            <div>

                                <table className='table table-bordered table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Fullname</th>
                                            <th>Telephone</th>
                                            <th style={{ textAlign: "center" }}>Recieve</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.newsletter !== null &&
                                            this.state.newsletter.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage).map(data => {
                                                return <tr>
                                                    <td>{data.email}</td>
                                                    <td>{data.fullname}</td>
                                                    <td>{data.telephone}</td>
                                                    <td onChange={() => {
                                                        this.checkUncheck(data.email);
                                                    }} style={{ textAlign: "center" }}> <input type='checkbox' /> </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            this.state.users !== null &&
                                            this.state.users.map(data => {
                                                return <tr>
                                                    <td>{data.username}</td>
                                                    <td>{data.fname + " " + data.lname}</td>
                                                    <td>{data.telephone}</td>
                                                    <td onChange={() => {
                                                        this.checkUncheck(data.username);
                                                    }} style={{ textAlign: "center" }}> <input type='checkbox' /> </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                <br />
                                <div className="btn-group">
                                    <button disabled={
                                        this.state.paginationIndex === 0
                                    }
                                        className='btn btn-outline-dark' onClick={() => {
                                            this.setState({
                                                paginationIndex: this.state.paginationIndex - this.state.recordsPerPage
                                            })
                                        }}>Previous</button>
                                    <button disabled={
                                        this.state.newsletter.slice(this.state.paginationIndex, this.state.paginationIndex + this.state.recordsPerPage + 1).length <= this.state.recordsPerPage
                                    } className='btn btn-outline-dark' onClick={() => {
                                        this.setState({
                                            paginationIndex: this.state.paginationIndex + this.state.recordsPerPage
                                        })
                                    }}>Next</button>
                                </div>
                                <br />
                                <br />
                                Write your Message Text
                                <hr />
                                <textarea value={this.state.message} onChange={(e) => {
                                    this.setState({
                                        message: e.target.value
                                    })
                                }} rows={4} className='form-control col-12' placeholder='Message'></textarea>
                                <br />
                                <button className='btn btn-success' onClick={() => {
                                    console.log(this.state.emailRecipients);
                                    this.state.emailRecipients.forEach(recepient => {
                                        axios.post(`${serverConfig.hostUrl}/mailer/message`, {
                                            from: serverConfig.SystemEmail,
                                            to: recepient,
                                            subject: "Promotional Email from Energytub",
                                            message: this.state.message
                                        })
                                            .then(_ => {
                                            }).catch(_ => {
                                                console.log(_);
                                            })
                                    })
                                    this.setState({
                                        message: ""
                                    })
                                }}>Send Message</button>{" | "}
                                <Link className='btn btn-primary' to={'/'}>Visit Site</Link>
                            </div>
                        </div>
                    }
                    <br />
                    <Link className='btn btn-success' to={'/'}>Go Back to Energytubs</Link>
                    <hr />
                    <br />
                </div>
                <Footer />
            </>
        ) : (
            <>
                <div style={{
                    height: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    fontWeight: 'bold'
                }}>
                    <div className='container'>
                        <div style={{ fontSize: 'xx-large', marginBottom: '20pt' }} >
                            Log In First
                        </div>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input className='form-control'
                                            placeholder='Username'
                                            type={'text'}
                                            style={{ marginBottom: "10pt" }} value={this.state.username} onChange={e => {
                                                this.setState({ username: e.target.value })
                                            }} />
                                    </div>
                                    <div className='col-md-12'>
                                        <input className='form-control'
                                            placeholder='Passphrase'
                                            type={'password'}
                                            style={{ marginBottom: "10pt" }} value={this.state.password} onChange={e => {
                                                this.setState({ password: e.target.value })
                                            }} />
                                    </div>
                                    <div className='col-md-12' >
                                        <Link href={'/'} >
                                            <button className='btn btn-dark'>
                                                Cancel
                                            </button>
                                        </Link>{" | "}
                                        <button className='btn btn-primary' onClick={this.hndlgn}>
                                            Log In
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
