import React, {  useState } from 'react';
import './testimonials.js'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function Testimonials() {
    const [SelectedTestimonials, setSelectedTestimonials] = useState({
        text: "",
        from: ""
    });


    // eslint-disable-next-line no-unused-vars
    const [Testtimonials] = useState(
        [
            {
                text: `I have lower back and leg pain/numbness and lower leg neuropathy, and also impaired balance and mobility. I am 84 years old and living alone. A fall in the shower at my age would be a disaster. I shopped for a hydrotherapy walk-in tub for some time, and finally was sold on Energy Tubs. The product expert (Michael) and the installation team (Enrique and Chuy) were all amazing and experienced and I could see that I could trust them in doing a great job of getting this wonderful tub installed and operating. They are all 5-star performers for Energy Tubs. I have used my hydrotherapy tub many times now and the tub’s performance has exceeded my expectations of personal hygiene, safety and medical hydrotherapy. The customer service, installation and use of my American walk in hydrotherapy tub has been a 5 star experience.`,
                from: "Charles C"
            },
            {
                text: `I would highly recommend Energy Tubs and their walk in tubs. I’ve had Parkinson’s disease for over 30 years and my walk in tub has given me the most pain relief I’ve ever had. I live in a very hot area and when the temperatures are at their worst, I am able cool down by sitting in my tub. This is so important because hot weather aggravates my parkinson's. The relief I get from the tub is amazing. I love my tub! Working with Energy Tubs was great. I thought it would be difficult getting my tub into a half bathroom. They were able to fit my new tub in without damaging my bathroom. In general, they were very clean and respectful of my house. I am really happy with the whole process. Thank you, Energy Tubs.`,
                from: "Judy Z"
            },
            {
                text: `I purchase one of these walk in tubs for my parents. Best investment I have ever made. it helps them sleep better at night. It also helps reduce stress, and most importantly they told me that it helps them soothe their pain their joints. There is nothing better than to see my parents so happy. Thanks Energy Tubs. `,
                from: "George P"
            },
            {
                text: `Can't praise them highly enough-great,friendly,fast service,they went above and beyond for help.I would definitely recommend them to friends and family and would be happy to use them again myself.`,
                from: "Shirley H"
            },
            {
                text: ` have so many good things to say about Energy Tubs, I don't know where to start. My mom and our entire family are thrilled with her new walk-in tub. We have had our tub for a few weeks, and it has truly improved the quality of life for my mother.
        We decided to purchase a walk in tub because my mother was having a hard time stepping in and out of her tub. My brother and I were concerned with her safety, but needed a better solution than helping my mom get in and out of her tub. We met with a few companies, but Energy Tubs was by far the best in every way.
        Most of the other salesmen we talked to seemed more interested in making a sale than finding out my mother's needs. With Mike and John it was the complete opposite, they actually cared. They were extremely patient and knowledgeable while explaining all the features that would benefit my mother the most. I could also say this about all the Energy Tubs staff we worked with. It almost felt like working with a family member the way they treated us.
        The tub itself is absolutely amazing. We got a tub with massage jets, a heated seat, a neck pillow, and other therapy features. The relaxation my mom gets from the tub has given her more energy and happiness in her life. She now enjoys it when it is time to take a bath or shower because it's like visiting your own personal spa. My mom normally smiles a lot but now you can't catch her without a smile on her face, especially when she gets out of her tub. She can also bathe independently and take her time while cleaning herself when before this was difficult. The best part is she can do this SAFELY.
        Knowing my mother is safe and happy has been a huge stress relief for my family and me. I want to thank Energy Tubs for making this possible. Their walk-in tub has truly changed my mom's way of life. My brother and I are really picky when it comes to who we work with and who we let around our mother. We are glad we found Energy Tubs. It feels great to be able to give a better quality of life to our mother since she has given my brother and I so much in life. Thank you, Energy Tubs`,
                from: "Charles A"
            },
            {
                text: `I just recently purchased an American Tub and Love it. The company representative I worked with was great. He was not a pressure salesman, he let the product information speak for itself. This was the only company I found when I was doing my research on Walk-In Tubs that was willing to work with you whether you were buying a tub today or 6 months from now. I found their tub price better than the other companies estimated price, I was quoted. I would highly recommend this company based on the outstanding customer service process I experienced during the purchasing of my tub`,
                from: "Alice J"
            },
            {
                text: `Purchased on these walk in bathtubs from Energy Tubs reduced the pain for my parents about 80 percent. After searching various walk in tubs company we found that Energy Tubs was the best company out there. There customer service and installation crew where amazing.`,
                from: "Sharkys A"
            },
            {
                text: `Love my tub, customer service is wonderful. Need another bathroom so I can get another.`,
                from: "Rachel M"
            },

        ]
    );
    var settings = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,

        autoplay: true,
        autoplaySpeed: 4000
    };


    return (
        <>
            <div className="container " style={{ paddingTop: "5vh", paddingBottom: "5vh" }}>
                <div className="row">
                    <div className="col-md-12">
                        <h2 style={{ color: "Gray", textAlign: "center", marginBottom: "10vh" }}>Testimonials</h2>

                        <Slider {...settings}>

                            {/* testimonials here */}
                            {Testtimonials.map(_data => {
                                return (
                                    <div style={{ cursor: "default" }} className="container" onMouseOver={() => {
                                        setSelectedTestimonials({
                                            text: _data.text,
                                            from: _data.from
                                        })
                                    }}>
                                        <div style={{ fontSize: "large", marginBottom: "10pt" }}>
                                            {_data.text.substring(0, 400)}{_data.text.length > 400 ? ". . . ." : ""}
                                        </div>
                                        <hr />
                                        <div style={{ textAlign: "right", color: "#355B86" }}>
                                            {(_data.from !== "" ? "- " : "") + _data.from}
                                        </div>
                                    </div>

                                );
                            })}

                        </Slider>

                    </div>
                </div>
            </div>

            {SelectedTestimonials.from !== "" &&
                <div className="container" style={{ marginTop: "5vh", cursor: "default" }}>
                    <h3 style={{ color: "#355B86" }}>
                        {SelectedTestimonials.from + " Says"}
                    </h3>
                    <p style={{fontSize:'large'}}>
                        {["", "", "", "", "", "", "", "", "", "",].map(_ => {
                            return <div style={{ display: "inline" }}>&nbsp;</div>
                        })}
                        <q>
                            <i>
                                {SelectedTestimonials.text}
                            </i>
                        </q>
                    </p>
                </div>
            }
        </>
    );
}

export default Testimonials;