import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import Testimonials from './testimonials'

function Warrenty() {
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
        }
    }, [])

    return (
        <div style={{ background: 'linear-gradient(to bottom right, rgb(220,220,220), white)' }}>
            <Header />
            <div style={{
                width: '100%',
                height: '100vh',
                backgroundImage: "url('/images/plumber2.jpg')",
                backgroundColor: 'black',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: 'white',
                textAlign: 'center',
                boxShadow: '1px 1px 50px gray'
            }}>
                <div style={{
                    height: '100%', width: '100%',
                    background: 'linear-gradient(to right,rgba(169,173,176,.9),rgba(169,173,176,.8),transparent)',
                    paddingLeft: '2vw',
                    display: 'flex', alignItems: 'center',
                }}>

                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-8' >
                                <div style={{ fontSize: '7vh', }}>WE GOT YOU COVERED</div>
                                <p style={{ fontSize: '2.5vh', marginLeft: '10%', marginRight: '10%' }}>Purchase with peace-of-mind, knowing that your investment and
                                    your tub will be protected for as long as you live in your home.
                                    We are committed to providing you with the utmost quality and
                                    customer service available in the industry.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container' style={{
                marginTop: '10vh',
            }}>

                <h2 style={{ color: 'orange', textAlign: 'center' }}>warranty</h2>
                <hr />
                <h5 style={{ color: 'gray', textAlign: 'center' }}>Limited warranty for original purchaser for Household usage</h5>
                <br />
                <p style={{ borderLeft: '4px solid orange', paddingLeft: '2%', paddingRight: '2%', borderRight: '4px solid orange', borderRadius: '20pt' }}>
                    To activate warranty, the product registration card must be mailed to 7274 E. Cypress Street, Covina, CA 91724 <br />
                    For as long as the original end-user purchaser owns the product, Energy Tubs provides the following limited lifetime warranty provided that such product has been installed by a licensed contractor, has remained in its original home installation, and has been used only for personal household use <br />
                    The fiberglass bathtub shell, stainless steel frame, door mechanism, door handle, vinyl door seal, and faucets are warranted to be free from defects in workmanship and material under normal use and conditions for one year from the original purchase date. The gelcoat tub finish is warranted to be free from defects in workmanship and material under normal use and conditions for one <br />
                    (1) year however, this warranty does not apply to fading, cracking, delaminating, or blistering due to unreported shipping damage, installation damage, negligence, improper maintenance, excessive wear, sun fading, scouring due to cleaning, or excessive weight load on the shell. The warranty for the door seal shall not apply to any failure resulting from improper installation, negligence, abuse, misuse, misapplication, alteration, modification or improper maintenance<br />
                    All supporting equipment, motors, electronic controls, tubing, plastic fittings, drain and overflow, and all plated finishes are also covered by a limited one-year <br />
                    (1) warranty_ Pump and faucet seals are not included in this warranty; however, these components are warranted to be free from defect in material and workmanship at the time of delivery to the original end-user purchaser.<br />
                    Energy Tubs will not be responsible for any water damage to structures or property for any reason including but not limited to manufacturer defects or improper installation_ Energy Tubs will assume no responsibility for the loss of the system, inconvenience due to loss, damage to real or personal property, or any other consequential damage. <br />
                    Energy Tubs will not be liable for any incidental expenses, material charges, or shipping charges in connection with removal or replacement of the purchased part or any part or parts of the system.
                    This warranty does not apply to any type of failure resulting from negligence, abuse, misuse, misapplication, improper installation, alteration or modification, chemical corrosion, or improper maintenance<br />
                    Energy Tubs does not warrant the installation of any of its products, including plumbing and electrical connections, improper or negligent installation of the product and components, and alterations or modifications of the product that may cause the product to malfunction_ However, the licensed contractor that performed the installation is legally mandated to provide a limited warranty as well.
                    TO THE EXTENT PERMITTED BY LAW, ALL IMPLIED WARRANTIES INCLUDING THOSE OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE HEREBY DISCLAIMED_ ENERGY TUBS AND SELLER HEREBY DISCLAIM ANY LIABILITY FOR SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES. Some states do not allow limitations as to how long a limited warranty lasts, or the exclusion or limitation of special, incidental, or consequential damages, so the above limitations and exclusions may not apply for some purchases.
                    This is the only warranty expressed or implied by EnergyTubs. This warranty may not be extended or modified by our agents or representatives. Our agents and representatives are not authorized to make any oral warranty that is inconsistent with the terms of this Limited Warranty<br />
                </p>

                <Testimonials />

                <br />
            </div>

            <Footer />
        </div>
    )
}

export default Warrenty