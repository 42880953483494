import React from 'react';
import serverConfig from './serverConfig';
export default function Thankyou() {
    return <div style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <div className='container' style={{ width: "100vw",textAlign:"center" }}>
            <div style={{display:"flex",justifyContent:"center",marginBottom:"4vh"}}><img alt='Thank you' src="images/headerLogo.png" style={{width:"5%"}}/><div style={{fontSize:"x-large",paddingTop:"2%",marginLeft:"1%"}}>EnergyTubs</div></div>
            <h1>Thank you for your order</h1>
            <hr/>
            <p>Your order has been received. We will contact you shortly</p>
            <p>Have a good day</p>
            <a style={{cursor:"pointer"}} href={serverConfig.fUrl} >Visit EnergyTubs</a>
        </div>
    </div>;
}
