const ip = "serveret.codeazatechnologies.com"
// const Fip = "energytubs.com" //frontEnd IP
//const ip = "190.92.179.184"
const Fip = "localhost" //frontEnd IP
const serverConfig = {
    hostUrl:`https://${ip}:4001`,
    fUrl:`https://${ip}`,
    // hostUrl:`http://${ip}:4001`,
    // fUrl:`http://${Fip}:3000`,
    SystemEmail:"abu8632632@gmail.com",
    AdminEmail:"abu8632632@gmail.com",
}
export default  serverConfig;


// sb-2ii5o12355458@personal.example.com
// i+y*C9KU 
